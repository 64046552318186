<template>
  <div>
    <Header />
    <EditGR />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import EditGR from "../components/EditGR.vue";
export default {
  components: {
    EditGR,
    Header
  },
  data() {
    return {};
  }
};
</script>
