<template>
    <div class="">
         <Settings/>
    </div>
</template>
<script>
import Settings from '../components/BaseSettings.vue';
export default {
    components: {
    Settings,
  },
     data() {
      return {
        
      }
  }
}

</script>