<template>
  <div class="">
    <Login />
  </div>
</template>
<script>
import Login from "../components/Login.vue";
export default {
  components: {
    Login
  },
  data() {
    return {};
  },
  mounted() {
    if (localStorage.getItem("Token") === null) {
      this.$router.push("/login");
      this.showCont = false;
    }
  }
};
</script>
