<template>
  <div>
    <Header />
    <EditSO />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import EditSO from "../components/EditSO.vue";
export default {
  components: {
    EditSO,
    Header
  },
  data() {
    return {};
  }
};
</script>
