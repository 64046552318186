<template>
  <div>
    <v-snackbar top :timeout="2000" :color="colormsg" v-model="snackbar">
      {{ msg__ }}
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      grow
      fixed
    >
      <v-tab>
        <v-icon style="color: blue">mdi-cloud-upload</v-icon
        ><b style="padding-left: 5px"> Not Uploaded</b>
      </v-tab>
      <v-tab>
        <v-icon style="color: green">mdi-cloud-check</v-icon
        ><b style="padding-left: 5px">Uploaded</b>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item style="max-height: 79vh; overflow: auto">
        <v-expansion-panels v-if="itemReg.length > 0">
          <v-expansion-panel v-for="(item, i) in itemReg" :key="i">
            <v-expansion-panel-header>
              <v-row :align="align" no-gutters style="color: #000">
                <v-col>
                  <v-icon style="color: #2196f3; font-size: 19px"
                    >mdi-receipt</v-icon
                  >&nbsp;&nbsp;&nbsp;{{ item.VoucherNo }}
                </v-col>
                <v-col style="text-align: right">
                  {{ item.VoucherDate }}
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" style="text-align: center">
                  <b style="color: #ff5252">Item Details</b>
                </v-col>
              </v-row>
              <div
                v-for="(item1, d) in item.VoucherDetails.length"
                :key="d"
              >
                <v-row style="border-bottom: 1px dotted #000; padding: 10px">
                  <v-col cols="6" style="padding: 1px">
                    {{ item.VoucherDetails[d].Barcode }}
                  </v-col>
                    <v-col cols="6" style="padding: 1px; text-align: right;">
                    {{ item.VoucherDetails[d].Quantity }}
                  </v-col>
                  <v-col cols="12" style="padding: 1px;">
                    {{ item.VoucherDetails[d].Remarks }}
                  </v-col>
                
                  <!-- <v-col cols="6" style="padding: 1px; text-align: right">
                    {{ item.VoucherDate }}
                  </v-col> -->
                </v-row>
              </div>
              <div>
                <v-row class="text-center" style="padding-top: 10px">
                  <v-col cols="12" style="padding-top: 10px">
                    <v-btn color="red" dark @click="dlt(i)">
                      <v-icon>mdi-delete</v-icon> </v-btn
                    >&nbsp;
                    <v-btn color="green" dark @click="edit(i)">
                      <v-icon>mdi-pencil</v-icon> </v-btn
                    >&nbsp;
                    <v-btn color="blue" dark @click="Upload(i)">
                      <v-icon>mdi-cloud-upload</v-icon>
                    </v-btn>

                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else style="padding: 20px; text-align: center">
          <v-img :src="require('@/assets/images/empty.gif')"></v-img>
          <h3>Empty List !</h3>
        </div>
      </v-tab-item>
      <v-tab-item style="max-height: 84vh; overflow: auto">
        <v-expansion-panels v-if="itemRegUploaded.length > 0">
          <v-expansion-panel v-for="(item, i) in itemRegUploaded" :key="i">
            <v-expansion-panel-header>
              <v-row :align="align" no-gutters style="color: #000">
                <v-col>
                  <v-icon style="color: #2196f3; font-size: 19px"
                    >mdi-receipt</v-icon
                  >&nbsp;&nbsp;&nbsp;{{ item.VoucherNo }}
                </v-col>
                <v-col style="text-align: right">
                  {{ item.VoucherDate }}
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" style="text-align: center">
                  <b style="color: #ff5252">Item Details</b>
                </v-col>
              </v-row>
              <div
                v-for="(item1, d) in item.VoucherDetails.length"
                :key="d"
              >
                <v-row style="border-bottom: 1px dotted #000; padding: 10px">
                  <v-col cols="6" style="padding: 1px">
                    {{ item.VoucherDetails[d].Barcode }}
                  </v-col>
                    <v-col cols="6" style="padding: 1px;text-align:right;">
                    {{ item.VoucherDetails[d].Quantity }}
                  </v-col>
                  <v-col cols="12" style="padding: 1px; text-align: left">
                    {{ item.VoucherDetails[d].Remarks }}
                  </v-col>
                
                  
                </v-row>
              </div>
              <div>
                <v-row class="text-center" style="padding-top: 10px">
                  <v-col cols="12" style="padding-top: 10px">
                    <v-btn color="brown" dark @click="upDelete(i)">
                      <v-icon>mdi-delete</v-icon> </v-btn
                    >&nbsp;
                    <v-btn color="green" dark @click="edit(i)" hidden>
                      <v-icon>mdi-pencil</v-icon> </v-btn
                    >&nbsp;
                    <v-btn color="yellow" dark @click="Uploade(i)" >
                      <v-icon>mdi-cloud-upload</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else style="padding: 20px; text-align: center">
          <v-img :src="require('@/assets/images/empty.gif')"></v-img>
          <h3>Empty List !</h3>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-bottom-navigation
      v-if="tab != 1"
      v-model="value"
      grow
      fixed
      bottom
      style="box-shadow: none"
    >
      <v-btn value="recent" @click="goback">
        <span>Back</span>

        <v-icon>mdi-arrow-left-thick</v-icon>
      </v-btn>
      <v-btn value="favorites" @click="UploadAll">
        <span>Upload All</span>

        <v-icon>mdi-upload</v-icon>
      </v-btn>

      <v-btn value="save" @click="saveItemRegister" hidden>
        <span>Save</span>

        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-dialog v-model="deleModal" persistent width="500">
      <v-card>
        <v-card-title
          style="color:rgb(0 99 145);font-size:17px;text-align:center"
          >Are you sure you want to delete?</v-card-title
        >

        
        <v-card-actions hidden>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="deleModal = false"
            style="background-color:#FB8C00;color:#FFF"
          >
            Cancel
          </v-btn>

          <v-btn
            small
            @click="Delete_pos"
            style="background-color:#2E7D32;color:#FFF"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-snackbar v-model="snack.snackbar" :timeout="2000">
     {{snack.msg}}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snack.snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    
  </div>
</template>
<script>
export default {
  data() {
    return {
       snack:{
        snackbar:false,
        msg:''
      },
      name: "reg",
      itemReg: [],
      itemRegUploaded: [],
      snackbar: false,
      msg__: "",
      URL__: localStorage.getItem("BaseURL"),
      url: localStorage.getItem("BaseURL"),
      colormsg: "",
      ios: false,
      value: null,
      tab: null,
     
       deleModal:false,
       dlePOS:''
    };
  },
  mounted() {
    window.callJsFunction1 = this.callJsFunction1;
    window.callJsFunction2 = this.callJsFunction2;

    window.PDTvouchersinglephy = this.PDTvouchersinglephy;
    window.PDTvoucherAllphy = this.PDTvoucherAllphy;


    if (localStorage.getItem("phyRegister") != null) {
      this.itemReg = JSON.parse(localStorage.getItem("phyRegister")) || "[]";
    }
    if (localStorage.getItem("phyRegisterUploaded") != null) {
      this.itemRegUploaded =
        JSON.parse(localStorage.getItem("phyRegisterUploaded")) || "[]";
    }

    this.string = this.URL__;
    this.substring = "https";
    this.Check_url = this.string.includes(this.substring);
    if (this.Check_url == true) {
      // alert(this.Check_url)
      this.ios = this.Check_url;
    } else {
      this.ios = this.Check_url;
    }


  },
  methods: {
    edit(pos) {
      localStorage.setItem("Editpos", pos);
      console.log("Pos" + JSON.stringify(this.itemReg[pos].VoucherDetails));
      localStorage.setItem(
        "PEditItemDetails",
        JSON.stringify(this.itemReg[pos].VoucherDetails)
      );

      const myobj = {
        // VoucherId: 0,
        // VoucherType: "GR",
        remarks: this.itemReg[pos].Remarks,
        number: this.itemReg[pos].VoucherNo,
        date: this.itemReg[pos].VoucherDate,
        title: this.itemReg[pos].Title,
      };
      localStorage.setItem("ephy", JSON.stringify(myobj));
      this.$router.push("/EditPS");
    },
    dlt(pos) {
      if (this.ios == true) {
      this.$confirm(
        {
          message: 'Want to delete  ?',
          button: {
            no: 'No',
            yes: 'Yes'
          },
 /**
          * Callback Function
          * @param {Boolean} confirm
          */
          callback: confirm => {
            if (confirm) {
        console.log(this.itemReg[pos]);
        this.itemReg.splice(pos, 1);
        localStorage.setItem("phyRegister", JSON.stringify(this.itemReg));
      }
       
       this.$forceUpdate();
  }
}
  )
      }else{
         this.deleModal = true;
          this.dlePOS = pos;
      }
      // this.$forceUpdate();
    },
      Delete_pos()
    {
        this.itemReg.splice(this.dlePOS, 1);
        localStorage.setItem("phyRegister", JSON.stringify(this.itemReg));
         this.deleModal = false;
    },
    upDelete(pos)
    {
//       this.$confirm('Are you sure you want to delete this item?', 'Confirmation', {
//   confirmButtonText: 'Yes',
//   cancelButtonText: 'No',
//   type: 'warning'
// }).then(() => {
//   // Code to execute when the user clicks "Yes"
// }).catch(() => {
//   // Code to execute when the user clicks "No" or dismisses the dialog
// });4

this.$confirm('Do you really want to leave this page?',{ title: 'Warning',confirmButtonText: 'Yes',
  cancelButtonText: 'No' })
    .then(result => {
    if (result) {
      console.log('User clicked OK.');
    
      // Do something here if the user clicked OK.
    } else {
      console.log('User clicked Cancel.');
     
      // Do something here if the user clicked Cancel.
    }
  })
  .catch(error => {
    console.log('An error occurred: ', error);
    // Handle the error here.
  });

    },
    uedlt(pos) {
      
      console.log(' clicked OK.');
  this.$confirm('Want to delete   ?', { title: 'Warning' })
    .then(result => {
    if (result) {
     
      this.itemRegUploaded.splice(pos, 1);
        localStorage.setItem(
          "phyRegisterUploaded",
          JSON.stringify(this.itemRegUploaded)
        );
    } 
    this.$forceUpdate();
  })
  .catch(error => {
    console.log('An error occurred: ', error);
    // Handle the error here.
  });

    },
    goback() {
      this.$router.push("physical");
       this.clearall();
    },
    UploadAll() {
      if (!this.itemReg.length) {
        this.colormsg = "red";
        this.msg__ = " Sorry.. Please add atleast one item";
        this.snackbar = true;
      } else {
        if(navigator.onLine) {
        if (this.ios == true) {
          for (this.i = 0; this.i < this.itemReg.length; this.i++) {
            const myobj = this.itemReg[this.i];
            this.url = localStorage.getItem("BaseURL");
            this.axios.post(this.url + "/api/PDTvoucher", myobj).then(
              (response) => {
                console.log("Result" + JSON.stringify(response.data));
                this.itemRegUploaded.push(myobj);
                localStorage.setItem(
                  "phyRegisterUploaded",
                  JSON.stringify(this.itemRegUploaded)
                );

                localStorage.setItem(
                  "phyRegister",
                  JSON.stringify(this.itemReg)
                );
                this.colormsg = "green";
                this.msg__ = " Uploaded successfully";
                this.snackbar = true;
                this.itemReg = [];
                localStorage.setItem(
                  "phyRegister",
                  JSON.stringify(this.itemReg)
                );
              },
              (error) => {
                console.log("error" + error);
                this.colormsg = "red";
                this.msg__ = " Try Again !" + error;
                this.snackbar = true;
              }
            );
          }
        } else {
          for (this.i = 0; this.i < this.itemReg.length; this.i++) {
        

            const myobj = this.itemReg[this.i];
            var obj = JSON.stringify(myobj);
            var method = "Post";
            var funtn_ = "PDTvoucherAllphy";

            var url_login = this.url + "/api/PDTvoucher";

            var JsonData = {
              Json: [
                {
                  URL: url_login,
                  Method: method,
                  Params: obj,
                  Function: funtn_,
                },
              ],
            };
            //---------------------------------------------------------------

            this.itemRegUploaded.push(myobj);
            localStorage.setItem(
              "phyRegisterUploaded",
              JSON.stringify(this.itemRegUploaded)
            );

          
            //---------------------------------------------------------------

            var aaa = JSON.stringify(JsonData);
            this.SendOBJ = aaa;
            window.interface.CommonMethod(this.SendOBJ);
          }
        }
      } 
      else {
  // internet connection is not available, display an error message or retry later
  this.colormsg = "red";
            this.msg__ = "Internet connection is not available !";
            this.snackbar = true;
        }
      }
    },
 
  
  Upload(i) {
    if(navigator.onLine) {
      
    if (this.ios == true) {
      // alert(this.itemReg[i]);
      const myobj = this.itemReg[i];
      this.url = localStorage.getItem("BaseURL");
      this.axios.post(this.url + "/api/PDTvoucher", myobj).then(
        (response) => {
          console.log("Result" + JSON.stringify(response.data));
          this.colormsg = "green";
          this.msg__ = " Uploaded successfully";
          this.snackbar = true;
          this.itemRegUploaded.push(myobj);
          localStorage.setItem(
            "phyRegisterUploaded",
            JSON.stringify(this.itemRegUploaded)
          );
          this.itemReg.splice(i, 1);
          localStorage.setItem("phyRegister", JSON.stringify(this.itemReg));
        },
        (error) => {
          console.log("error" + error);
          this.colormsg = "red";
          this.msg__ = " Try Again !";
          this.snackbar = true;
        }
      );
    } else {

      // alert("AAAA"+i)
            const myobj = this.itemReg[i];
            var obj = JSON.stringify(myobj);
            var method = "Post";
            var funtn_ = "PDTvouchersinglephy";

            var url_login = this.url + "/api/PDTvoucher";

            var JsonData = {
              Json: [
                {
                  URL: url_login,
                  Method: method,
                  Params: obj,
                  Function: funtn_,
                },
              ],
            };
            //---------------------------------------------------------------

           this.colormsg = "green";
          this.msg__ = " Uploaded successfully";
          this.snackbar = true;
          this.itemRegUploaded.push(myobj);
          localStorage.setItem(
            "phyRegisterUploaded",
            JSON.stringify(this.itemRegUploaded)
          );
          this.itemReg.splice(i, 1);
          localStorage.setItem("phyRegister", JSON.stringify(this.itemReg));
            //---------------------------------------------------------------

            var aaa = JSON.stringify(JsonData);
            this.SendOBJ = aaa;
            window.interface.CommonMethod(this.SendOBJ);
    }
    this.$forceUpdate();
  } 
      else {
  // internet connection is not available, display an error message or retry later
  this.colormsg = "red";
            this.msg__ = "Internet connection is not available !";
            this.snackbar = true;
        }
  },
  Uploade(i) {
    if(navigator.onLine) {
      
    if (this.ios == true) {
      // alert(this.itemReg[i]);
      const myobj = this.itemRegUploaded[i];
      this.url = localStorage.getItem("BaseURL");
      this.axios.post(this.url + "/api/PDTvoucher", myobj).then(
        (response) => {
          console.log("Result" + JSON.stringify(response.data));
          this.colormsg = "green";
          this.msg__ = " Uploaded successfully";
          this.snackbar = true;
         
          
        },
        (error) => {
          console.log("error" + error);
          this.colormsg = "red";
          this.msg__ = " Try Again !";
          this.snackbar = true;
        }
      );
    } else {

      // alert("AAAA"+i)
            const myobj = this.itemRegUploaded[i];
            var obj = JSON.stringify(myobj);
            var method = "Post";
            var funtn_ = "PDTvouchersinglephy";

            var url_login = this.url + "/api/PDTvoucher";

            var JsonData = {
              Json: [
                {
                  URL: url_login,
                  Method: method,
                  Params: obj,
                  Function: funtn_,
                },
              ],
            };
            //---------------------------------------------------------------

           this.colormsg = "green";
          this.msg__ = " Uploaded successfully";
          this.snackbar = true;
        
            //---------------------------------------------------------------

            var aaa = JSON.stringify(JsonData);
            this.SendOBJ = aaa;
            window.interface.CommonMethod(this.SendOBJ);
    }
    this.$forceUpdate();
  } 
      else {
  // internet connection is not available, display an error message or retry later
  this.colormsg = "red";
            this.msg__ = "Internet connection is not available !";
            this.snackbar = true;
        }
  },
  PDTvouchersinglephy(res) {
   console.log("Testing " +res);
  },
  PDTvoucherAllphy(res) {
    // console.log(res);
    localStorage.setItem("phyRegister", JSON.stringify(this.itemReg));
            this.colormsg = "green";
            this.msg__ = " Uploaded successfully";
            this.snackbar = true;
            this.itemReg = [];
            localStorage.setItem("phyRegister", JSON.stringify(this.itemReg));
  },
  callJsFunction1(val) {
    //  alert("val"+val)
    // localStorage.setItem("Token", JSON.stringify(val.token));
    this.i = localStorage.getItem("native_i");
    const myobj = this.itemReg[this.i];
    this.colormsg = "green";
    this.msg__ = " Uploaded successfully";
    this.snackbar = true;
    this.itemRegUploaded.push(myobj);
    localStorage.setItem(
      "phyRegisterUploaded",
      JSON.stringify(this.itemRegUploaded)
    );
    this.itemReg.splice(this.i, 1);
    localStorage.setItem("phyRegister", JSON.stringify(this.itemReg));
    console.log(val);
  },
  callJsFunction2(val) {
    localStorage.setItem("phyRegister", JSON.stringify(this.itemReg));
    this.colormsg = "green";
    this.msg__ = " Uploaded successfully";
    this.snackbar = true;
    this.itemReg = [];
    localStorage.setItem("phyRegister", JSON.stringify(this.itemReg));
    console.log(val);
  },
   },
};
</script>
<style>
.aa:nth-child(odd) {
  background-color: #c1ffd7;
}
.aa:nth-child(even) {
  background-color: #b5deff;
}
</style>
