<template>
  <div>
    <Header />
    <PDTscan />
  </div>
</template>
<script>
import PDTscan from "../components/Pdtscan.vue";
import Header from "../components/Header.vue";
export default {
  components: {
    PDTscan,
    Header
  },
  data() {
    return {};
  }
};
</script>
