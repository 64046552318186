<template>
    <div class="">
     
      <v-card-text style="font-size:0.8rem !important;">
           
        <v-col cols="12">
          <v-card
            color="#fff"
            theme="light"
            style="margin-top:5px;"
          >
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-text style="padding-top:2em;">
                <v-text-field label="Select Date"
                v-model="check_date"
                required
                dense 
                type="date"
                style="margin-bottom:2em;">
              </v-text-field>
              <v-combobox
              v-show="notmorethan1"
              v-model="selected_branch"
              label="Select Branch"
              :items="branches"
              dense
             ></v-combobox>
             <v-text-field 
            disabled
             v-show="!notmorethan1"
             v-model="selected_branch"
             label="Select Branch"
             >

             </v-text-field>
             
  
               
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
  
                <v-btn text color="primary" @click="checkWithDate">ok</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-card-text style="height: 20px; position: relative">
            <v-btn
              absolute
              dark
              fab
              top
              right
              color="blue"
              @click="dialog = !dialog"
            >
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </v-card-text>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div style="width: 100%;text-align:left;">
              <v-avatar
              class="ma-3"
              size="115"
              rounded="0"
            >
              <!-- <v-img :src="require('@/assets/images/bg.png')"></v-img> -->
              <v-img src="https://img.freepik.com/free-vector/cashier-supermarket-isolated-person-smiling-woman-employee-uniform-standing-cash-desk-store-employee-working-grocery-shop-market-retail-commerce_575670-1280.jpg?w=360"></v-img>
            </v-avatar>
            <v-card-subtitle style="color:#000;padding-bottom: 0px;padding-top: 0px;text-align:left;">
              <i class="fa fa-calendar"></i> &nbsp; {{check_date}}
             </v-card-subtitle>
             <v-card-subtitle style="color:#000;padding-bottom: 0px;padding-top: 0px;text-align:left;">
             
              <v-avatar
              color="green"
              class="ma-3 "
              size="30"
            > {{  checkOpenedstatus()}}
          </v-avatar>
            <v-avatar
            color="red"
            class="ma-3 "
            size="30"
          > {{  checkClosedstatus()}}
          </v-avatar>
             </v-card-subtitle>
           
             
           
          </div>
              <div style="width: 100%;text-align:right;">
              
                <v-card-subtitle style="color:#000;    margin-top: 5em;">
                  <v-row no-gutters>
                    <v-col cols="4" style="font-size:0.6rem; color:#000;font-weight:800;">
                     
                       Cash<br>
                       Credit<br>
                       Digital pay<br>
                       Others
                       <!-- Discount<br>
                       Tax<br>
                       Round Off -->
                      
                    </v-col>
                    <v-col cols="8">
                      <b style="color:#000;">{{formatPrice(SaleData.cash )}}</b><br>
                      <b style="color:#000;">{{formatPrice(SaleData.credit )}}</b><br>
                      <b style="color:#000;">{{formatPrice(SaleData.creditCard )}}</b><br>
                      <b style="color:#000;">{{formatPrice(SaleData.others )}}</b>
                      <!-- <b style="color:#000;">(-){{formatPrice(counteritem.discount )}}</b><br>
                      <b style="color:#000;">{{formatPrice(counteritem.tax )}}</b><br>
                      <b style="color:#000;">(-){{formatPrice(counteritem.roundOff )}}</b> -->
                    </v-col>
                  </v-row>
                 </v-card-subtitle>
               
                <hr style="    margin-left: 15px;">

                <v-card-actions style="text-align:right;float:right; color:#000;padding-top:0px">
                
                  Total : &nbsp;<b>{{formatPrice(SaleData.totSale - (SaleData.discount + SaleData.roundOff ) )}}</b> 
                 
                </v-card-actions>
               
              </div>
            

             
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
             
              <div style="width: 100%;text-align:left;">
                
                <v-card-subtitle style="color:#000;">
                  <v-col style="font-size:0.6rem; color:#000;padding-left:0px;padding-bottom: 0px;">Other Details</v-col>
                  <hr>
                  <v-row no-gutters>
                   
                    <v-col cols="5" style="font-size:0.5rem; color:#000;font-weight:800;">
                     
                       Total Tax<br>
                       Discount<br>
                       Round Off<br>
                       Sales Return
                       
                      
                    </v-col>
                    <v-col cols="7" style="text-align:right">
                      <b style="color:#000;">{{formatPrice(SaleData.tax )}}</b><br>
                      <b style="color:#000;">{{formatPrice(SaleData.discount )}}</b><br>
                      <b style="color:#000;">{{formatPrice(SaleData.roundOff )}}</b><br>
                      <b style="color:#000;">{{formatPrice(SaleData.saleReturn )}}</b><br>
                      
                    </v-col>
                  </v-row>
                 </v-card-subtitle>
              
               

                <v-card-actions style="text-align:right;float:right; color:#000;">
                
                <!-- //here total   -->
                </v-card-actions>
              </div>
              <div style="width: 100%;text-align:right;">
                
                <v-card-subtitle style="color:#000;">
                  <v-col style="font-size:0.6rem; color:#000;padding-left:0px;padding-bottom: 0px;">Cash Details</v-col>
                  <hr>
                  <v-row no-gutters>
                    <v-col cols="4" style="font-size:0.5rem; color:#000;font-weight:800;">
                     
                       Open Cash<br>
                       Cash Sale<br>
                       Payment
                       
                      
                    </v-col>
                    <v-col cols="8">
                      <b style="color:#000;">{{formatPrice(SaleData.openingAmt )}}</b><br>
                      <b style="color:#000;">{{formatPrice(SaleData.cash )}}</b><br>
                      <b style="color:#000;">{{formatPrice(SaleData.paidAmt )}}</b>
                      
                    </v-col>
                  </v-row>
                 </v-card-subtitle>
                
               
                <hr style="    margin-left: 15px;">
                <v-card-actions style="text-align:right;float:right; color:#000;padding-top:0px">
                
                  Balance : &nbsp;<b>{{formatPrice( (SaleData.openingAmt + SaleData.cash )-SaleData.paidAmt )}}</b> 
                 
                </v-card-actions>
              </div>
            

             
            </div>
           
          </v-card>
          
        </v-col>
      </v-card-text>
      <v-card
      :loading="loading"
      style="margin-top: 0px !important;font-size:0.8rem !important;"
      class="mx-auto my-12"
      max-width="374"
      v-if="counterData.length != 0"
    >
      
  
     
  
     
  
      <v-divider class="mx-4 mb-1"></v-divider>
  
      <v-card-actions style="font-size:0.8rem !important;" >
        <!-- <label>Counter List</label><i :class="show ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" style="text-align:right;" aria-hidden="true" @click="show = !show" ></i> -->
      <v-container >
        <v-row justify="space-between">
          <v-col  cols="10"  @click="show = !show" >
            Counter Details
          </v-col>
          <v-col  cols="2" style="text-align:right;"  @click="show = !show" >
            <i :class="show ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
          </v-col>
        </v-row>
      </v-container>
      
      </v-card-actions>
  
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
  
          <v-card-text style="font-size:0.8rem !important;padding-left: 0px;
     padding-right: 0px;">
           
            <v-col cols="12">
              <v-card
                color="#f1f1f1"
                theme="light"
                style="margin-top:10px;"

                v-for="(counteritem, i) in counterData" :key="i"
              >
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div style="width: 100%;text-align:left;">
                  <v-avatar
                  class="ma-3"
                  size="105"
                  rounded="0"
                >
                  <v-img :src="require('@/assets/images/bg.png')"></v-img>
                  
                </v-avatar>
                <v-card-subtitle style="color:#000;padding-bottom: 0px;padding-top: 0px;text-align:left;">
                 <i class="fa fa-user"></i> &nbsp; {{counteritem.username}}
                </v-card-subtitle>
                <v-card-subtitle style="color:#000;padding-top: 0px;padding-bottom: 0px;text-align:left;">
                  <i class="fa fa-calendar" ></i> &nbsp; {{counteritem.openingTime.split("T")[0]}}
                  <br><i class="fa fa-clock-o" ></i> &nbsp; {{counteritem.openingTime.split("T")[1]}}
                 
                </v-card-subtitle>
                <v-card-subtitle style="color:#000;padding-top: 0px;padding-bottom: 0px;text-align:left;" >
                  <i class="fa fa-money" ></i> &nbsp; {{formatPrice(SaleData.openingAmt)}}
                </v-card-subtitle>
                <v-card-subtitle style="color:#000;padding-top: 0px;padding-bottom: 0px;text-align:left;" v-show="counteritem.counterStatus  == 'C'">
                  <i class="fa fa-calendar" style="color:red;"></i> &nbsp; {{counteritem.closingTime.split("T")[0]}} 
                  <br><i class="fa fa-clock-o" style="color:red;"></i>&nbsp;  {{counteritem.closingTime.split("T")[1]}}
                </v-card-subtitle>
                <v-card-subtitle style="color:#000;padding-top: 0px;padding-bottom: 0px;text-align:left;" v-show="counteritem.counterStatus  == 'C'">
                  <i class="fa fa-money" style="color:red;"></i> &nbsp; {{formatPrice(SaleData.closingAmt)}}
                </v-card-subtitle>
                <v-card-subtitle style="color:#000;padding-top: 0px;padding-bottom: 0px;text-align:left;" v-show="counteritem.counterStatus  == 'C'">
                  <i class="fa fa-cubes" style="color:blue;"></i> &nbsp; {{SaleData.saleCount}} Nos
                </v-card-subtitle>
              </div>
                  <div style="width: 100%;text-align:right;">
                    <v-card-title class="text-h5" style="justify-content: center;">
                      <v-avatar
                      style="float:right;"
                      class="ma-3 "
                      size="55"
                      :color="counteritem.counterStatus  == 'C' ? 'red' : 'green'"
                      rounded="20"
                    > {{counteritem.counterName}}
                    </v-avatar>
                    

                    </v-card-title>
    
                    <v-card-subtitle style="color:#000;    margin-top: 5em;">
                      <v-row no-gutters>
                        <v-col cols="4" style="font-size:0.5rem; color:#000;">
                         
                           Cash<br>
                           Credit<br>
                           Digital pay<br>
                           Others
                           <!-- Discount<br>
                           Tax<br>
                           Round Off -->
                          
                        </v-col>
                        <v-col cols="8">
                          <b style="color:#000;">{{formatPrice(counteritem.cash )}}</b><br>
                          <b style="color:#000;">{{formatPrice(counteritem.credit )}}</b><br>
                          <b style="color:#000;">{{formatPrice(counteritem.creditCard )}}</b><br>
                          <b style="color:#000;">{{formatPrice(counteritem.others )}}</b>
                          <!-- <b style="color:#000;">(-){{formatPrice(counteritem.discount )}}</b><br>
                          <b style="color:#000;">{{formatPrice(counteritem.tax )}}</b><br>
                          <b style="color:#000;">(-){{formatPrice(counteritem.roundOff )}}</b> -->
                        </v-col>
                      </v-row>
                     </v-card-subtitle>
                   
                    <hr style="    margin-left: 15px;">
    
                    <v-card-actions style="text-align:right;float:right; color:#000;padding-top:0px">
                    
                      Total : &nbsp;<b>{{formatPrice(counteritem.totSale - (counteritem.discount + counteritem.roundOff ) )}}</b> 
                     
                    </v-card-actions>
                   
                  </div>
                
    
                 
                </div>
                <div class="d-flex flex-no-wrap justify-space-between">
                 
                  <div style="width: 100%;text-align:left;">
                    
                    <v-card-subtitle style="color:#000;">
                      <v-col style="font-size:0.5rem; color:#000;padding-left:0px;padding-bottom: 0px;">Other Details</v-col>
                      <hr>
                      <v-row no-gutters>
                       
                        <v-col cols="5" style="font-size:0.5rem; color:#000;">
                         
                           Total Tax<br>
                           Discount<br>
                           Round Off<br>
                           Sales Return
                           
                          
                        </v-col>
                        <v-col cols="7" style="text-align:right">
                          <b style="color:#000;">{{formatPrice(counteritem.tax )}}</b><br>
                          <b style="color:#000;">{{formatPrice(counteritem.discount )}}</b><br>
                          <b style="color:#000;">{{formatPrice(counteritem.roundOff )}}</b><br>
                          <b style="color:#000;">{{formatPrice(counteritem.saleReturn )}}</b><br>
                          
                        </v-col>
                      </v-row>
                     </v-card-subtitle>
                  
                   
    
                    <v-card-actions style="text-align:right;float:right; color:#000;">
                    
                    <!-- //here total   -->
                    </v-card-actions>
                  </div>
                  <div style="width: 100%;text-align:right;">
                    
                    <v-card-subtitle style="color:#000;">
                      <v-col style="font-size:0.5rem; color:#000;padding-left:0px;padding-bottom: 0px;">Cash Details</v-col>
                      <hr>
                      <v-row no-gutters>
                        <v-col cols="4" style="font-size:0.5rem; color:#000;">
                         
                           Open Cash<br>
                           Cash Sale<br>
                           Payment
                           
                          
                        </v-col>
                        <v-col cols="8">
                          <b style="color:#000;">{{formatPrice(counteritem.openingAmt )}}</b><br>
                          <b style="color:#000;">{{formatPrice(counteritem.cash )}}</b><br>
                          <b style="color:#000;">{{formatPrice(counteritem.paidAmt )}}</b>
                          
                        </v-col>
                      </v-row>
                     </v-card-subtitle>
                    
                   
                    <hr style="    margin-left: 15px;">
                    <v-card-actions style="text-align:right;float:right; color:#000;padding-top:0px">
                    
                       Balance : &nbsp;<b>{{formatPrice( (counteritem.openingAmt + counteritem.cash )-counteritem.paidAmt )}}</b> 
                     
                    </v-card-actions>
                  </div>
                
    
                 
                </div>
               
              </v-card>
              
            </v-col>
          </v-card-text>
        </div>
      </v-expand-transition>
      
     </v-card>
  
           
       
    </div>
</template>
<script>
import axios from 'axios'

export default {

     data() {
      return {
         title:'Sales Details',
         URL__: localStorage.getItem("BaseURL"),
         TodayDate:  null,
         SaleData:[],
         counterData:[],
         integerLength:3,
         show: false,
         dialog:false,
         check_date:null,
         selected_branch:null,
         branches:[],
         ios:false,
         notmorethan1:false
       
          
      }
     
      },
      mounted(){
 
        this.string = this.URL__;
    this.substring = "https";
    this.Check_url = this.string.includes(this.substring);
    if (this.Check_url == true) {
      // alert(this.Check_url)
      this.ios = this.Check_url
    } 
    else {
      this.ios = this.Check_url;
    } 
        // alert("url"+this.Check_url)
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        // today = yyyy + '-' + mm + '-' + '21';//dd ;
        today = yyyy + '-' + mm + '-' + dd ;
        this.TodayDate = today
        this.check_date = today;
       
        window.NativeCounterSales_ = this.NativeCounterSales_;
        this.branches = JSON.parse(localStorage.getItem("Branches"))
        this.branches.push("All")
        // this.branches.push("All")
        
        // alert("Bra"+this.branches)
        if (localStorage.getItem('isbranchCode') === null || localStorage.getItem('isbranchCode') === '') {
          this.selected_branch = "All";
          if(this.branches.length > 0)
            {
              this.notmorethan1 = true;
              // alert("aa")
            }
            else {
          
              this.selected_branch = localStorage.getItem("isbranchCode");
              this.notmorethan1 = false;
              // alert("bb")
            }
            // 
   
        }else{
          if(localStorage.getItem('role') === "Administrator")
          {
            this.selected_branch = "All";
            this.notmorethan1 = true;
            // alert("a")
          }
          else{
            this.notmorethan1 = false;
            this.selected_branch = this.branches[0];
            // alert("bb")
          }

        }

        this.checkWithDate();
      },
      methods:{
        

        checkWithDate()
        {
          this.SaleData = [];
          this.counterData = [];
          this.dialog = false;
           if(this.selected_branch === null)
           {
            this.selected_branch = "All"
           }
           
          if (this.ios == true) {
          this.axios
        .get(
          this.URL__ + "/api/counter/GetCounterSaleSummary?ForDate="+this.check_date+"&BranchCode="+this.selected_branch+"&WithSummary=True",
          null
        )
        .then(
          response => {
           
            this.SaleData =  response.data.result[0]; 
            let sdta = response.data.result;
            for (let i = 1; i < sdta.length; i++) {
                    this.counterData.push(sdta[i])
             }
             this.checkClosedstatus();
             this.checkOpenedstatus();
          },
          error => {
            this.showw = false;
            if (error.response.status == 404) {
              this.showw = false;
            }
            // this.error = "Something went wrong..!";
          }
        );
          }else{
            const myobj = {
    
  };
var obj = JSON.stringify(myobj);
var method = "Get";
var funtn_ = "NativeCounterSales_";
var url_ = this.URL__ + "/api/counter/GetCounterSaleSummary?ForDate="+this.check_date+"&BranchCode="+this.selected_branch+"&WithSummary=True";


var JsonData = 
{
"Json":
[  
   {"URL":url_,"Method":method, "Params":obj,"Function":funtn_}
]

};


var aaa = JSON.stringify(JsonData);
this.SendOBJ = aaa ;
window.interface.CommonMethod(this.SendOBJ);

          }
        },

        NativeCounterSales_(res)
    {
       let obj = JSON.parse(res);
       let length = JSON.stringify(obj.result.length);
       this.SaleData = obj.result[0];
      
            for (let i = 1; i < length; i++) {
                 this.counterData.push(obj.result[i])
                   
             }
              this.checkClosedstatus();
             this.checkOpenedstatus();
       
 
    },
   
        formatPrice(value)
        {
          if(value != undefined || !isNaN(value) )
          {
            return value.toFixed(localStorage.getItem("symbol"))
          }else{
            let dummyval = 0;
            return dummyval.toFixed(localStorage.getItem("symbol"))
          }
         
        //  
        },
       
        checkClosedstatus()
          {
            const arr = this.counterData;
            const nameToCount = 'C';

              let count = 0;
              for (let i = 0; i < arr.length; i++) {

                // console.table(arr[i]);
                if (arr[i].counterStatus === nameToCount) {
                  count++;
                }
              }
           return count;
            
          },
          checkOpenedstatus()
          {
            const arr = this.counterData;
            const nameToCount = 'O';

              let count = 0;
              for (let i = 0; i < arr.length; i++) {

                // console.table(arr[i]);
                if (arr[i].counterStatus === nameToCount) {
                  count++;
                }
              }
           return count;
            
          },
        

      }
}

</script>