<template>
  <div>
    <v-form>
      <v-container>
        <v-row>
          <v-col>
          <v-img :src="require('@/assets/images/seo.gif')" class="elevation-0" max-height="280"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="Settings.url"
              label="Base URL"
              placeholder="www.example.com"
              outlined
              @change="setPrinterURL()"
              dense
            ></v-text-field>
            <v-text-field
              v-model="Settings.display_time"
              label="Display Time"
              placeholder="In Seconds ( 2 )"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12" sm="4" md="4" style="padding-top:0px !important;margin-top:-5px">
            <v-text-field
              v-model="Settings.printerurl"
              label="Printer URL"
              placeholder="www.example.com / 190.89.0.1.23"
              outlined
              dense
             
              @change="GetPrinters(Settings.printerurl)"
            ></v-text-field>
            <v-checkbox id="chk" style="margin-top:0px;padding-top:0px;font-size: 10px !important;" :small="true" label="Copy Base Url" @change="copy" v-model="isChecked"></v-checkbox>
            </v-col>
           <v-col cols="12" sm="4" md="4" style="padding-top:0px !important;margin-top:-5px">
           <v-autocomplete
              :items="printers"
               v-model="BarcodePrinter"
               outlined
              label="Select Printer"
              dense
              append-icon="mdi-magnify"
          ></v-autocomplete>
           </v-col>
            <v-col cols="12" sm="4" md="4" style="padding-top:0px !important;margin-top:-5px">
           <v-autocomplete
              :items="Type"
               v-model="BarcodeType"
               outlined
              label="Barcode Type"
              dense
              append-icon="mdi-magnify"
          ></v-autocomplete>
           </v-col>
            </v-row>
        <v-row style="margin-top:2px !important;">
          <v-col cols="6" sm="6" md="6" hidden>

             <v-switch
              v-model="Settings.showcost"
              label="Show Cost"
              color="info"
               :input-value="false"
                hide-details
                @change="setcost"
            ></v-switch>
           
          </v-col>
          <v-col cols="6" sm="4" md="4" hidden>
            <v-switch
             :input-value="false"
              v-model="Settings.showsupp"
              label="Show Supplier"
              color="info"
               @change="setSup"
              hide-details
            ></v-switch>
          
          </v-col>
           
          <v-col cols="12" class="text-center">
            <v-btn color="info" class="mr-6" @click="save_settings">
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      name: "Settings",
      BarcodePrinter:'',
      BarcodeType:'',
      Settings: {
        url: "",
        printerurl:"",
        display_time: "",
        showcost:false,
        showsupp: false,
        
      },
       URL__: null,
       ios: false,
       isChecked: false,
       printers: [],
       Type:['Item','Shelf','Mini']
    };
  },
  watch: {
    BarcodePrinter: function() {
    // alert(this.BarcodePrinter)
     localStorage.setItem('bPrinter',this.BarcodePrinter)
    },
    BarcodeType: function() {
    //  alert(this.BarcodeType)
     localStorage.setItem('btype',this.BarcodeType)
    },
    
  },

  mounted() {
    if (localStorage.getItem("BaseURL") !== null) {
      this.URL__ = localStorage.getItem("BaseURL")
      this.Settings.url = localStorage.getItem("BaseURL");
      this.Settings.printerurl = localStorage.getItem("printerurl");
      this.Settings.display_time = localStorage.getItem("display_time");
      this.a =  localStorage.getItem("showcost");
      this.b =  localStorage.getItem("showsupp");
      this.Settings.showcost = this.a;//.replaceAll("^\"|\"$", "");
      this.Settings.showsupp = this.b;//.replaceAll("^\"|\"$", "");
      this.checkNative(this.Settings.url);
      this.GetPrinters(this.Settings.printerurl);
      // this.GetItems(this.Settings.url);
    }
     if (localStorage.getItem("bPrinter") !== null) {
       this.BarcodePrinter = localStorage.getItem("bPrinter");
     }
      if (localStorage.getItem("btype") !== null) {
       this.BarcodeType = localStorage.getItem("btype");
     }
     
    
     window.getPinterList = this.getPinterList;
     window.getItemList = this.getItemList;
     
  },

  methods: {
   
    save_settings() {
      
      localStorage.setItem("BaseURL", this.Settings.url);
      localStorage.setItem("display_time", this.Settings.display_time);
      localStorage.setItem("printerurl", this.Settings.printerurl);
      localStorage.setItem("showcost", this.Settings.showcost);
      localStorage.setItem("showsupp", this.Settings.showsupp);
     
    
      this.$router.push("/login");
    
    },
   
    GetPrinters(pURL) {
      // alert(pURL)
      if (this.ios == true) {
       
          this.b_itemname = "";
          this.b_rate = "";
        this.axios
          .get(pURL + "/api/common/getprinterlist",
            null
          )
          .then(
            (response) => {
              console.log("Result" + JSON.stringify(response.data));
              this.printers = []
              this.printers = response.data;
              // this.GetItems(this.Settings.url);
            },
            (error) => {
              this.showw = false;
              if (error.response.status == 404) {
                this.showw = false;
              }
              // this.error = "Something went wrong..!";
            }
          );
      } else {
        //------------------------------------------------------------------------
        
        const myobj = {
         
        };
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "getPinterList";
        var url_ = pURL + "/api/common/getprinterlist"

        var JsonData = {
          Json: [{ URL: url_, Method: method, Params: obj, Function: funtn_ }],
        };

        var aaa = JSON.stringify(JsonData);
        this.SendOBJ = aaa;
        window.interface.CommonMethod(this.SendOBJ);
      }
    },
    getPinterList(res) {
      this.printers = []
      let obj = JSON.parse(res);
      this.printers = obj;
      // this.GetItems(this.Settings.url);
      // alert("Res "+res)
    },
   

    
    setPrinterURL()
    {
       if(this.Settings.url !== null)
       {
         
         this.URL__ = this.Settings.url;
         this.checkNative(this.Settings.url);
         this.GetPrinters(this.Settings.url);
       }else{
        // alert("dd")
       }
    },
    copy()
    {
      if(this.isChecked)
      {
        this.Settings.printerurl = this.Settings.url;
        // this.GetPrinters()
        this.GetPrinters(this.Settings.printerurl);
      }else{
        this.Settings.printerurl = '';
      }
       
    },
    checkNative(url_val)
    {
    this.string = url_val;
    this.substring = "https";
    this.Check_url = this.string.includes(this.substring);
    if (this.Check_url == true) {
      // alert(this.Check_url)
      this.ios = this.Check_url;
    } else {
      this.ios = this.Check_url;
    }
    }
  },
}
</script>
