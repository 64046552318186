<template>
  <div>
    <v-snackbar top :timeout="2000" :color="colormsg" v-model="snackbar">
      {{ msg__ }}
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
    <v-container class="grey lighten-5 mb-6">
      <v-row no-gutters>
        <v-col
          style="
            text-align: center;
            padding-bottom: 0px;
            border-bottom: 1px dashed #000;
            background: #cfcfcf4a;
          "
        >
          Physical Stock
        </v-col>
      </v-row>
      <v-row no-gutters>
        <div class="row" style="padding: 5px; font-size: 13px">
          <div class="col" style="" @click="gsheet = !gsheet">
            <label for=""
              >Number# : <b>{{ physical.number }}</b></label
            ><br />
            <label for=""
              >Warehouse : <b>{{ physical.warehouse }}</b></label
            >
          </div>
          <div class="col" style="text-align: right" @click="gsheet = !gsheet">
            <label for=""
              >Date : <b>{{ physical.date }}</b></label
            ><br />
            <label for=""
              >Remarks : <b>{{ physical.remarks }}</b></label
            >
          </div>
        </div>
      </v-row>
      <v-row no-gutters>
        <div
          class="col"
          style="
            text-align: center;
            padding-bottom: 0px;
            border-bottom: 1px dashed #000;
            background: #cfcfcf4a;
          "
        >
          Item Details
        </div>
      </v-row>
      <v-row no-gutters style="font-weight: normal; font-size: 15px">
        <v-col class="col-1 pp2 text-left">Sl </v-col>
        <v-col class="col-4 pp2 text-left">Item Name </v-col>
        <v-col class="col-3 pp2 text-left">Barcode </v-col>
        <v-col class="col-3 pp2 text-right">Quantity </v-col>
        <v-col class="col-1 pp2 text-right"> </v-col>
      </v-row>
      <div
        class="scrl"
        style="padding-top: 5px;
          text-align: left;
          font-size: 13px;
          font-weight: bold;
        "
      >
        <v-row no-gutters v-for="(item, i) in itemDetails" :key="i">
          <v-col class="col-1 pp2 text-left">{{ i + 1 }} </v-col>
          <v-col class="col-4 pp2 text-left">{{ item.Remarks }} </v-col>
          <v-col class="col-3 pp2 text-left">{{ item.Barcode }} </v-col>
          <v-col class="col-3 pp2 text-right">{{ item.Quantity }} </v-col>
          <v-col class="col-1 pp2 text-right">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="#000"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  style="font-size: 19px"
                >
                  mdi-dots-vertical
                </v-icon>
              </template>

              <v-list>
                <v-list-item @click="edit(i)">
                  <v-icon style="font-size: 22px; color: green">
                    mdi-pencil-circle
                  </v-icon>
                </v-list-item>
                <v-list-item @click="dlt(i)">
                  <v-icon style="font-size: 22px; color: red">
                    mdi-delete-circle
                  </v-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <v-container>
        <v-row>
          <v-bottom-navigation
            v-model="value"
            grow
            fixed
            bottom
            style="box-shadow: none"
          >
            <v-btn value="recent" @click="clearall">
              <span>Clear All</span>

              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-btn value="favorites" @click="itemReg">
              <span>Registers</span>

              <v-icon>mdi-view-list</v-icon>
            </v-btn>
            <v-btn value="upload" @click="Upload" hidden>
              <span>Upload</span>

              <v-icon>mdi-cloud-upload</v-icon>
            </v-btn>

            <v-btn value="save" @click="saveItemRegister">
              <span>Save</span>

              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn value="add" @click="sheet = !sheet;edit_ = false">
              <span>Add</span>

              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
          </v-bottom-navigation>
        </v-row>
        <v-bottom-sheet v-model="gsheet">
          <v-sheet class="text-center" height="350px" style="padding: 10px">
            <v-row style="border-bottom: 1px dotted #000">
              <v-col cols="12">Physical Stock</v-col>
            </v-row>

            <v-row style="padding-left: 10px; padding-right: 10px">
              <v-col cols="6">
                <v-text-field
                  v-model="physical.number"
                  outlined
                  label="Physical stock number"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="physical.warehouse"
                  label="Warehouse"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="padding-left: 10px; padding-right: 10px">
              <v-col cols="6">
                <v-text-field
                  v-model="physical.date"
                  outlined
                  label="Date"
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="physical.remarks"
                  label="Remarks"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="padding-top: 0px"
                ><v-btn color="red" dark @click="gsheet = !gsheet">
                  <v-icon> mdi-keyboard-backspace</v-icon> </v-btn
                >&nbsp;
                <v-btn color="warning" dark @click="clear">
                  <v-icon>mdi-close-box</v-icon> </v-btn
                >&nbsp;
                <v-btn color="blue" dark @click="addreceipt">
                  <v-icon>mdi-checkbox-marked</v-icon>
                </v-btn></v-col
              >
            </v-row>
          </v-sheet>
        </v-bottom-sheet>

        <v-bottom-sheet v-model="sheet" persistent>
          <v-sheet class="text-center" height="350px" style="padding: 10px">
            <v-row style="border-bottom: 1px dotted #000">
              <v-col cols="12">Add Item</v-col>
              <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
                <v-checkbox
                  style="margin-top: 0px; padding-top: 0px"
                  v-model="checkbox"
                  label="Always open cam scanner"
                  id="checkbox1zaal1"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row style="padding-left: 10px; padding-right: 10px">
              <v-col cols="6">
                <v-text-field
                  v-model="b_barcode"
                  outlined
                  id="ebar"
                  autofocus
                  label="Barcode"
                  append-icon="mdi-barcode"
                  @keyup.enter="moveToNext2()"
                  @change="getItemname"
                  @click:append="scanbarcode"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="b_quantity"
                  label="Quantity"
                  type="number"
                  outlined
                  @keyup.enter="additem"
                  id="quan"
                  ref="b_quantity"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              style="padding-left: 10px; padding-right: 10px; margin-top: -30px"
            >
              <v-col cols="12">
                <v-text-field
                  label="Item Name"
                  v-model="b_itemname"
                  @keyup.enter="additem"
                  outlined
                ></v-text-field>
                <!-- <v-text-field
                  label="Item Name"
                  v-model="pos"
                  outlined
                ></v-text-field> -->
              </v-col>
            </v-row>
            <v-row v-show="!edit_">
              <v-col cols="12">
                <v-btn color="red" dark @click="sheet = !sheet">
                  <v-icon> mdi-keyboard-backspace</v-icon> </v-btn
                >&nbsp;
                <v-btn color="warning" dark @click="clearitems">
                  <v-icon>mdi-close-box</v-icon> </v-btn
                >&nbsp;
                <v-btn color="blue" dark @click="additem">
                  <v-icon>mdi-checkbox-marked</v-icon>
                </v-btn>
              </v-col></v-row
            >
            <v-row v-show="edit_">
              <v-col cols="12">
                <v-btn color="red" dark @click="sheet = !sheet">
                  <v-icon> mdi-keyboard-backspace</v-icon> </v-btn
                >&nbsp;
                <v-btn color="warning" dark @click="clearitems">
                  <v-icon>mdi-close-box</v-icon> </v-btn
                >&nbsp;
                <v-btn color="blue" dark @click="update">
                  <v-icon>mdi-checkbox-marked</v-icon>
                </v-btn>
              </v-col></v-row
            >
          </v-sheet>
        </v-bottom-sheet>
      </v-container>
    </v-container>

    <v-dialog v-model="BarcodeModal" persistent width="500">
      <v-card>
        <v-card-title
          style="color: rgb(0 99 145); font-size: 17px; text-align: center"
          >Scan barcode</v-card-title
        >

        <v-card-text>
          <StreamBarcodeReader
            @decode="(a) => onDecode(a)"
            @loaded="() => onLoaded()"
          ></StreamBarcodeReader>
        </v-card-text>
        <v-card-actions hidden>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="BarcodeModal = false"
            style="background-color: #fb8c00; color: #fff"
          >
            Cancel
          </v-btn>

          <v-btn
            small
            @click="BarcodeModal = false"
            style="background-color: #2e7d32; color: #fff"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snack.snackbar" :timeout="2000">
      {{ snack.msg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snack.snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleModal" persistent width="500">
      <v-card>
        <v-card-title
          style="color: rgb(0 99 145); font-size: 17px; text-align: center"
          >{{ modalmsg }}</v-card-title
        >

        <v-card-actions hidden>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="deleModal = false"
            style="background-color: #fb8c00; color: #fff"
          >
            Cancel
          </v-btn>

          <v-btn
            small
            @click="Delete_pos"
            style="background-color: #2e7d32; color: #fff"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      snack: {
        snackbar: false,
        msg: "",
      },
      physical: {
        number: "",
        date: "",
        warehouse: "",
        remarks: "",
      },
      b_itemname: "",
      b_barcode: "",
      b_quantity: "",
      sheet: false,
      itemDetails: [],
      BarcodeModal: false,
      edit_: false,
      pos: "",
      checkbox: false,
      gsheet: false,
      VoucherDetails: [],
      snackbar: false,
      msg__: "",
      colormsg: "",
      ios: false,
      deleModal: false,
      dlePOS: "",
      modalmsg: "",
      URL__: localStorage.getItem("BaseURL"),
       Mydate__:''
    };
  },
  created() {
    if (localStorage.getItem("physicalItemDetails") !== null) {
      this.itemDetails = JSON.parse(
        localStorage.getItem("physicalItemDetails") || "[]"
      );
    }
    if (localStorage.getItem("pstck") !== null) {
      this.physical = JSON.parse(localStorage.getItem("pstck"));
    }
    if (localStorage.getItem("showcam") !== null) {
      var checked = JSON.parse(localStorage.getItem("showcam"));

      if (checked == true) {
        this.checkbox = true;
        // document.getElementById("checkbox1zaal1").checked = true;
      } else {
        this.checkbox = false;
        // document.getElementById("checkbox1zaal1").checked = false;
      }
      this.checkbox = eval(localStorage.getItem("showcam"));
    }
  },
  mounted() {
    
     this.Mydate__ = new Date().toISOString().slice(0,10);
    
    this.string = this.URL__;
    this.substring = "https";
    this.Check_url = this.string.includes(this.substring);
    if (this.Check_url == true) {
      // alert(this.Check_url)
      this.ios = this.Check_url;
    } else {
      this.ios = this.Check_url;
    }
    var today = new Date();

    this.physical.date =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear();

          this.physical.number = "";
          // this.physical.date = "";
          this.physical.warehouse = "";
          this.physical.remarks = "";
          this.itemDetails = [];
      window.getResultPhysic = this.getResultPhysic;
      
  },
  watch: {
    checkbox: function () {
      localStorage.setItem("showcam", this.checkbox);
    },
     Mydate__: function(val) {
        this.physical.date = val;
       
      },
  },
  methods: {
    onDecode(a) {
      console.log("Result Barcode " + a);
      // this.text = a;
      if (a != null || a != "") {
        this.b_barcode = a;
        this.BarcodeModal = false;
        this.moveToNext('quan');
      }
    },
    moveToNext2() {
    
    const nextField = document.getElementById('quan'); // Get the next field
    nextField.focus(); // Move focus to the next field
  
   },
    moveToNext(nextFieldId) {
      const currentField = document.activeElement; // Get the current active field
      if (currentField.value.length > 0) {
        const nextField = document.getElementById(nextFieldId); // Get the next field
        nextField.focus(); // Move focus to the next field
      }
    },
    clear() {
      this.physical = {};
      localStorage.setItem("pstck", JSON.stringify(this.physical));
    },
    clearall() {
      if (this.ios == true) {
        this.$confirm(
        {
          message: 'Want to delete  ?',
          button: {
            no: 'No',
            yes: 'Yes'
          },
 /**
          * Callback Function
          * @param {Boolean} confirm
          */
          callback: confirm => {
            if (confirm) {
          this.physical.number = "";
          // this.physical.date = "";
          this.physical.warehouse = "";
          this.physical.remarks = "";
          this.itemDetails = [];
        }
        this.$forceUpdate();
      }
    }
      )
        
      } else {
        this.deleModal = true;
        this.dlePOS = "abc";
        this.modalmsg = "Do you want to clear all data?";
      }
    },
    Delete_pos() {
      if (this.dlePOS == "abc") {
        this.deleModal = false;

        this.physical.number = "";
        // this.physical.date = "";
        this.physical.warehouse = "";
        this.physical.remarks = "";
        this.itemDetails = [];
      } else {
        this.itemDetails.splice(this.dlePOS, 1);
        localStorage.setItem("ItemDetails", JSON.stringify(this.itemDetails));
        this.deleModal = false;
      }
    },
      getItemname()
    {

      this.moveToNext2();
 if (this.ios == true) {
      this.axios
        .get(
          this.URL__ + "/api/item/getitembybarcode?barcode=" + this.b_barcode,
          null
        )
        .then(
          response => {
            console.log("Result" + JSON.stringify(response.data));
             const a =  JSON.stringify(response.data);
             if (a != '"Barcode does not exist"') {
              this.b_itemname = response.data.ItemName;
              
            } else {
              this.showw = false;
              // alert("Item Not Found !")
              this.snack.msg = "Item Not Found !";
              this.snack.snackbar = true;

              //  setTimeout(() => {
              //    window.history.back()
              //   }, 2000);
             
            }
          },
          error => {
            this.showw = false;
            if (error.response.status == 404) {
              this.showw = false;
            }
            // this.error = "Something went wrong..!";
          }
        );
      }else{
        //------------------------------------------------------------------------

          const myobj = {
           barcode: this.b_barcode
          };
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "getResultPhysic";
        var url_ = this.URL__ + "/api/item/getitembybarcode?barcode=" + this.b_barcode;

        var JsonData = 
      {
        "Json":
        [  
           {"URL":url_,"Method":method, "Params":obj,"Function":funtn_}
        ]

      };
        
    
      var aaa = JSON.stringify(JsonData);
      this.SendOBJ = aaa ;
       window.interface.CommonMethod(this.SendOBJ)
      }
    },

     getResultPhysic(res)
    {
       let obj = JSON.parse(res);
    //  alert("toekn"+obj.ItemName);
      this.b_itemname = obj.ItemName;
     // alert("Res "+res)
    },

    clearitems() {
      this.b_barcode = "";
      this.b_itemname = "";
      this.b_quantity = "";
      //  localStorage.setItem("pstck", JSON.stringify(this.itemDetails));
    },
    onLoaded() {
      console.log("load");
    },
    scanbarcode() {
      //alert("aaa");
      this.BarcodeModal = true;
    },
    additem() {
      if (
        this.b_barcode == "" ||
        this.b_quantity == ""
      ) {
        // alert("Enter All details");
        this.snack.msg = "Enter All details";
        this.snack.snackbar = true;
        document.getElementById("ebar").focus();
        // if (this.b_barcode == null) {
        //   alert("Enter All details");
      } else {
        var obj = {
          // receiptNo: this.physical.receiptNo,
          Barcode: this.b_barcode,
          Remarks: this.b_itemname,
          Quantity: this.b_quantity,
        };

        this.itemDetails.push(obj);
        localStorage.setItem(
          "physicalItemDetails",
          JSON.stringify(this.itemDetails)
        );
        if (this.checkbox == true) {
          this.BarcodeModal = true;
        }

        // this.physical.receiptNo = "";
        this.b_barcode = "";
        this.b_itemname = "";
        this.b_quantity = "";
        // this.$refs.ebar.$el.focus();
        document.getElementById("ebar").focus();
      }

      // this.sheet = false;
    },

    saveItemRegister() {
      if (this.physical.number == null || this.physical.number == "") {
        this.snack.msg = "Please Enter Physical Stock Number";
        this.snack.snackbar = true;
      } else if (!this.itemDetails.length) {
        this.snack.msg = "Please Add Atleast one item";
        this.snack.snackbar = true;
      } else {
        this.Register = [];
        if (localStorage.getItem("phyRegister") != null) {
          this.Register = JSON.parse(localStorage.getItem("phyRegister"));
        }
        this.itemDetails_2 = JSON.parse(
          localStorage.getItem("physicalItemDetails")
        );
        const myobj = {
          // VoucherId: 0,
          VoucherType: "PS",
          VoucherNo: this.physical.number,
          VoucherDate: this.physical.date,
          Remarks: this.physical.remarks,
          Title: this.physical.warehouse,
          VoucherDetails: this.itemDetails_2,
        };

        this.Register.push(myobj);
        localStorage.setItem("phyRegister", JSON.stringify(this.Register));
        this.physical.number = "";
        // this.physical.date = "";
        this.physical.warehouse = "";
        this.physical.remarks = "";
        this.itemDetails = [];
        localStorage.setItem("cangoback",0)
        this.snack.msg = "Saved";
        this.snack.snackbar = true;
      }
    },
    edit(position) {
      this.edit_ = true;
      console.log(this.itemDetails[position]);
      this.pos = position;
      // this.b_receiptNo = this.itemDetails[position].receiptNo;
      this.b_barcode = this.itemDetails[position].Barcode;
      this.b_itemname = this.itemDetails[position].Remarks;
      this.b_quantity = this.itemDetails[position].Quantity;
      this.sheet = true;
      // console.log("edit"+JSON.parse(this.itemDetails[position]))
    },
    addreceipt() {
      if (this.physical.number == null) {
        this.snack.msg = "Please Enter Receipt Number";
        this.snack.snackbar = true;
        // alert("");
      } else if (this.physical.date == null) {
        // alert("please select a date");
        this.snack.msg = "Please select a Date";
        this.snack.snackbar = true;
      } else {
        localStorage.setItem("pstck", JSON.stringify(this.physical));
        this.gsheet = false;
      }
    },
    update() {
      var position = this.pos;
      //Find index of specific object using findIndex method.
      this.objIndex = this.itemDetails.findIndex((obj) => obj.id == position);

      
      // this.itemDetails[position].receiptNo = this.b_receiptNo;
      this.itemDetails[position].Barcode = this.b_barcode;
      this.itemDetails[position].Remarks = this.b_itemname;
      this.itemDetails[position].Quantity = this.b_quantity;
      //---
      if (this.b_quantity == null || this.b_quantity == "" || this.b_barcode == "" || this.b_barcode == null  ) 
      {
         this.snack.msg = "Please Enter Receipt Number"
         this.snack.snackbar = true;
      }else
      {
      localStorage.setItem(
        "physicalItemDetails",
        JSON.stringify(this.itemDetails)
      );
      this.snack.msg = "Updated Succesfully"
         this.snack.snackbar = true;
      this.sheet = false;
      this.pos = "";
      this.b_barcode = "";
      this.b_itemname = "";
      this.b_quantity = "";
      // this.$refs.ebar.$el.focus();
      document.getElementById("ebar").focus();
      }
    },
    dlt(position) {
      if (this.ios == true) {
        this.$confirm(
        {
          message: 'Want to delete  ?',
          button: {
            no: 'No',
            yes: 'Yes'
          },
 /**
          * Callback Function
          * @param {Boolean} confirm
          */
          callback: confirm => {
            if (confirm) {
          console.log(this.itemDetails[position]);
          this.itemDetails.splice(position, 1);
          localStorage.setItem(
            "physicalItemDetails",
            JSON.stringify(this.itemDetails)
          );
          this.snackbar = true;
          this.msg__ = "Deleted Successfully.";
          this.colormsg = "green";
        }
      
       
       this.$forceUpdate();
  }
}
  )
      } else {
        this.deleModal = true;
        this.dlePOS = position;
        this.modalmsg = "Are you sure you want to delete?";
      }
    },
    Upload() {
      this.itemObject = [];
      for (this.i = 0; this.i < this.itemDetails.length; this.i++) {
        // console.log(this.itemDetails[this.i].barcode);
        const obbj = {
          VoucherId: 0,
          Barcode: this.itemDetails[this.i].Barcode,
          Quantity: this.itemDetails[this.i].Quantity,
          Remarks: this.itemDetails[this.i].Remarks,
        };
        this.itemObject.push(obbj);
      }
      this.VoucherDetails.push(this.itemObject);
      const myobj = {
        VoucherId: 0,
        VoucherType: "PS",
        VoucherNo: this.physical.number,
        VoucherDate: this.physical.date,
        Remarks: this.physical.remarks,
        VoucherDetails: this.VoucherDetails,
      };
      this.url = localStorage.getItem("BaseURL");
      this.axios.post(this.url + "/api/PDTvoucher", myobj).then(
        (response) => {
          console.log("Result" + JSON.stringify(response.data));
          this.colormsg = "green";
          this.msg__ = " Uploaded successfully";
          this.snackbar = true;
        },
        (error) => {
          console.log("error" + error);
          this.colormsg = "green";
          this.msg__ = " Uploaded successfully";
          this.snackbar = true;
        }
      );
    },
    itemReg() {
      this.$router.push("phyitemReg");
    },
  },
};
</script>
<style scopped>
.pp2 {
  padding-bottom: 0px !important;
}
.pp3 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  font-size: 14px;
}
.scrl {
 /* min-height: 57vh !important;
  max-height: 57vh !important;*/
  overflow: auto;
 
  min-height: 60vh;
}
</style>
