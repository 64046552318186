<template>
  <div>
    <Header />
    <PitemReg />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import PitemReg from "../components/PitemReg.vue";
export default {
  components: {
    PitemReg,
    Header
  },
  data() {
    return {};
  },
  // beforeRouteLeave(to, from, next) {
  // //  console.log("sample name");
   
  //   const answer = window.confirm('Do you want to leave this page?');
  //   if (answer) {
  //     next();
  //   } else {
  //     next(false);
  //   }
  // }
};
</script>
