<template>
  <div>
    <v-form>
      <v-container>
        <v-row>
          <v-col>
            <div class="text-right">
              <v-btn
                @click="opensettings"
                style="margin-bottom: -6em; z-index: 99999"
                color="info"
                fab
                dark
                x-small
                outlined
              >
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </div>
            <v-img
              :src="require('@/assets/images/imglogin.png')"
              class="elevation-0"
              max-height="280"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="authinfo.username"
              label="Username"
              placeholder="user user"
              outlined
            ></v-text-field>
            <v-text-field
              type="password"
              v-model="authinfo.password"
              label="Password"
              placeholder="**********"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn
              color="info"
              rounded
              large
              cols="12"
              class="mr-6"
              @click="login"
              v-if="log_check"
            >
              Login
            </v-btn>
            <v-btn
             
              rounded
              large
              cols="12"
              class="mr-6"
             
              v-else
            >
            <v-icon class="mdi-spin">mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center" style="font-size: 14px">
            <b style="color: red"> {{ error }}</b>
          </v-col>
          <!-- <v-col cols="12" class="text-center" style="    font-size: 14px;">
            <b style="color:red;"><p id="demo"></p></b>
          </v-col> -->
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
export default {
  data() {
    return {
      authinfo: {
        username: "admin", //"admin",
        password: "maR@2023", //"12345",
        VersionCode: 2,
      },
      url: localStorage.getItem("BaseURL"),
      getData: "",
      URL__: localStorage.getItem("BaseURL"),
      ios: false,
      // eslint-disable-next-line no-unused-vars
      error: "",
      error2: "",
      userRole: "",
      log_check:true,
    };
  },
  mounted() {
    this.string = this.URL__;
    this.substring = "https";
    this.Check_url = this.string.includes(this.substring);
    if (this.Check_url == true) {
      // alert(this.Check_url)
      this.ios = this.Check_url;
    } else {
      this.ios = this.Check_url;
    }

    window.callJsFunction = this.callJsFunction;
    window.setcurrency_details = this.setcurrency_details;
    window.setAllpermissionvalues =  this.setAllpermissionvalues;
    window.setDetailsinfo_ = this.setDetailsinfo_;
    window.setBranchinfo = this.setBranchinfo;
    window.setnativelogin = this.setnativelogin;
    this.GetItems(this.URL__)
    window.setItemList = this.setItemList;
    // this.CompanySalePerm();
    // window.setCompanySalePerm1 = this.setCompanySalePerm1;
  },
  methods: {
    login() {
      this.log_check = false;
      if (this.authinfo.username == "" || this.authinfo.password == "") {
        // alert("Enter all details")
        this.error = "Enter all details !";
        this.log_check = true;
      } else if (this.ios == true) {
        //---------------------------------
     
        const myobj = {
          UserName: this.authinfo.username,
          Password: this.authinfo.password,
          VersionCode: 2,
        };
        this.axios
          .post(this.url + "/api/user/authenticate?isCreateToken=true", myobj)
          .then(
            (response) => {
              console.log("res" + JSON.stringify(response.data.token));
              if (response.data.token != "undefined") {
                localStorage.setItem(
                  "Token",
                  JSON.stringify(response.data.token).replaceAll('"', "")
                );
              }
              // localStorage.setItem("Accounts", JSON.stringify(response.data))
              
              this.getCurrency_();
              // this.$router.push("/");
            },
            (error) => {
              // console.log("error" + error);
              if (error.response.status === 401) {
                this.error = "Invalid Username or Password";
                this.log_check = true;
              } else {
                this.error = "Something went wrong..!";
                this.log_check = true;
              }
              // localStorage.setItem("Token", error);
            }
          );

        //---------------------------------
      } else {
        //native call starts here
        const myobj = {
          UserName: this.authinfo.username,
          Password: this.authinfo.password,
          VersionCode: 2,
        };
        var obj = JSON.stringify(myobj);
        var method = "Post";
        var funtn_ = "setnativelogin";

        var url_login = this.url + "/api/user/Authenticate?isCreateToken=true";

        var JsonData = {
          Json: [
            { URL: url_login, Method: method, Params: obj, Function: funtn_ },
          ],
        };

        var aaa = JSON.stringify(JsonData);
        this.SendOBJ = aaa;
        window.interface.CommonMethod(this.SendOBJ);
            setTimeout(() => {
            this.log_check = true;
          }, 2000);
      }
    },

    setnativelogin(res) {
      this.log_check = true;
      let obj = JSON.parse(res);
      localStorage.setItem("Token", obj.token);
      // localStorage.setItem("Accounts", obj);
      this.getCurrency_();
      
      
    },
    
    //********************
    getCurrency_() {
      if (this.ios == true) {
        this.axios
          .get(this.URL__ + "/api/currency/GetPrimaryCurrency", null)
          .then(
            (response) => {
              console.log("Result" + JSON.stringify(response.data));
              localStorage.setItem(
                "GetPrimaryCurrency",
                JSON.stringify(response.data)
              );
              localStorage.setItem(
                "symbol",
                JSON.stringify(response.data.DecimalPlaces)
              );

              this.getAllpermissionvalues();

              // // alert("s"+this.$CurrencySymbol )
              // this.GetCurrencyDetails(response.data.CurrencySymbol);
            },
            (error) => {
              console.log("error" + error);
              this.error = "Something went wrong..!";
            }
          );
      } else {
        const myobj = {};
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "setcurrency_details";
        var url_ = this.URL__ + "/api/currency/GetPrimaryCurrency";

        var JsonData = {
          Json: [{ URL: url_, Method: method, Params: obj, Function: funtn_ }],
        };

        var aaa = JSON.stringify(JsonData);
        this.SendOBJ = aaa;
        window.interface.CommonMethod(this.SendOBJ);
      }
    },
    setcurrency_details(res) {
      let obj = JSON.parse(res);

      // alert("Currency "+obj.DecimalPlaces+" "+ obj.CurrencySymbol);
      localStorage.setItem("GetPrimaryCurrency", JSON.stringify(res));
      // this.GetCurrencyDetails(obj.CurrencySymbol);
      localStorage.setItem("CurrencyDetails", JSON.stringify(res));
      this.$CurrencySymbol = obj.DecimalPlaces;

      localStorage.setItem("Currency", obj.CurrencySymbol);
      localStorage.setItem("symbol", obj.DecimalPlaces);
      this.getAllpermissionvalues();
    },

    //********************
    getAllpermissionvalues()
    {
      if (this.ios == true) {
        this.axios
          .get(this.URL__ + "/api/common/GetFormPermissions?RoleName=POS&FormNames=frmItem~frmPrintBarcode~frmCounterSaleSummary~frmSaleReg", null)
          .then(
            (response) => {
              // console.log("Result" + JSON.stringify(response.data));
              localStorage.setItem("permissionList",JSON.stringify(response.data))
              // this.$router.push("/");
              this.getDetailsinfo_();
            },
            (error) => {
              console.log("error" + error);
              this.error = "Something went wrong..!";
            }
          );
      }else{
        const myobj = {};
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "setAllpermissionvalues";
        var url_ = this.URL__ + "/api/common/GetFormPermissions?RoleName=POS&FormNames=frmItem~frmPrintBarcode~frmCounterSaleSummary~frmSaleReg";

        var JsonData = {
          Json: [{ URL: url_, Method: method, Params: obj, Function: funtn_ }],
        };

        var aaa = JSON.stringify(JsonData);
        this.SendOBJ = aaa;
        window.interface.CommonMethod(this.SendOBJ);
      }
    },
    setAllpermissionvalues(result1)
    {
      let objresult1 = JSON.parse(result1);
      localStorage.setItem("permissionList",result1)
      // this.$router.push("/");
      this.getDetailsinfo_();
    },
    //********************

    getDetailsinfo_() {
      if (this.ios == true) {
        this.axios
          .get(this.URL__ + "/api/user/Getuser/" + this.authinfo.username, null)
          .then(
            (response) => {
              console.table(response.data);
              localStorage.setItem("Accounts",JSON.stringify(response.data))
              localStorage.setItem("isbranchCode",JSON.stringify(response.data.branchCode).replaceAll('"', ""));
              localStorage.setItem("role",JSON.stringify(response.data.userType).replaceAll('"', ""));
              this.GetBranchinfo();
            },
            (error) => {
              // this.error = "Something went wrong..!";
            }
          );
      } else {
        const myobj = {};
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "setDetailsinfo_";
        var url_ = this.URL__ + "/api/user/Getuser/" + this.authinfo.username;

        var JsonData = {
          Json: [{ URL: url_, Method: method, Params: obj, Function: funtn_ }],
        };

        var aaa = JSON.stringify(JsonData);
        this.SendOBJ = aaa;
        window.interface.CommonMethod(this.SendOBJ);
      
      }
    },
    setDetailsinfo_(res_user) {
           let res_ = JSON.parse(res_user);
           localStorage.setItem("isbranchCode", JSON.stringify(res_.branchCode).replaceAll('"', "") );
           localStorage.setItem("role", JSON.stringify(res_.userType).replaceAll('"', ""));
           localStorage.setItem("Accounts",JSON.stringify(res_))
          // alert(res_)
          //  this.$router.push("/");
          // alert(localStorage.getItem("Accounts"))
          this.GetBranchinfo();
    },
    //*************************************
    GetBranchinfo() {

if (this.ios == true) {
this.axios.get(this.URL__ + "/api/branch", null)
  .then(
    response => {
      // console.log("Result" + JSON.stringify(response.data));
      localStorage.setItem( "Branches", JSON.stringify(response.data) );
      this.$router.push("/");
    },
    error => {
      console.log("error" + error);
      this.error = "Something went wrong..!";
    }
  );
}else{
   const myobj = {
    
    };
  var obj = JSON.stringify(myobj);
  var method = "Get";
  var funtn_ = "setBranchinfo";
  var url_ = this.URL__ + "/api/branch";

  var JsonData = 
{
  "Json":
  [  
     {"URL":url_,"Method":method, "Params":obj,"Function":funtn_}
  ]

};
  

var aaa = JSON.stringify(JsonData);
this.SendOBJ = aaa ;
 window.interface.CommonMethod(this.SendOBJ)
}
    },
//----------------------*******************save from native*************----------
setBranchinfo(res1)
    {
       window.localStorage.removeItem( "Branches");
     
       let obj = JSON.parse(res1);
      
      if(obj.length != undefined)
      {
        // alert("leng"+JSON.stringify(obj))
        window.localStorage.setItem( "Branches", JSON.stringify(obj) );
      }
      this.$router.push("/");
      
 
    },


    callJsFunction(val) {
      // localStorage.setItem("Token", JSON.stringify(val.token));

      const obj21 = JSON.parse(val);
      localStorage.setItem("Token", obj21.token);

      this.$router.push("obj21.token");
      // alert(val)
      this.getCurrency();
      this.$router.push("/");
    },

    opensettings() {
      this.$router.push("/settings");
    },
    GetItems(pURL) {
      
      if (this.ios == true) {
       
        
        this.axios
          .get(pURL + "/api/item",
            null
          )
          .then(
            (response) => {
              console.log("Result s" + JSON.stringify(response.data));
             
            },
            (error) => {
              this.showw = false;
              if (error.response.status == 404) {
                this.showw = false;
              }
              // this.error = "Something went wrong..!";
            }
          );
      } else {
        //------------------------------------------------------------------------
       
        const myobj = {
         
        };
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "setItemList";
        var url_ = pURL + "/api/item"

        var JsonData = {
          Json: [{ URL: url_, Method: method, Params: obj, Function: funtn_ }],
        };

        var aaa = JSON.stringify(JsonData);
        this.SendOBJ = aaa;
        window.interface.CommonMethod(this.SendOBJ);
        // alert(this.SendOBJ)
      }
    },
    setItemList(res_items){
      // alert(res_items)
      // let obj = JSON.parse(res_items);
      // alert(obj)
      // this.printers = obj
    },


  },
};
</script>
