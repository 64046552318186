<template>
    <div>
      <Header />
      <Preferences />
    </div>
  </template>
  <script>
  import Header from "../components/Header.vue";
  import Preferences from "../components/Preferences.vue";
  export default {
    components: {
      Preferences,
      Header
    },
    data() {
      return {};
    }
  };
  </script>
  