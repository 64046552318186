<template>
  <div>
    <v-snackbar Bottom :timeout="2000" color="green" v-model="snackbar">
      Updated Successfully !
    </v-snackbar>
    <v-container class="grey lighten-5 ">
      <v-row no-gutters>
        <v-col
          style="
    text-align: center;
            padding-bottom: 0px;
            border-bottom: 1px dashed #000;
            background: #cfcfcf4a;
          "
        >
          Goods Receipt
        </v-col>
      </v-row>
      <v-row no-gutters>
        <div class="row" style="padding: 5px; font-size: 13px">
          <div class="col" style="" @click="gsheet = !gsheet">
            <label for=""
              >Number : <b>#{{ goods.number }}</b></label
            ><br />
            <label for=""
              >Supplier : <b>{{ goods.title }}</b></label
            >
          </div>
          <div class="col" style="text-align: right" @click="gsheet = !gsheet">
            <label for=""
              >Date : <b>{{ goods.date }}</b></label
            ><br />
            <label for=""
              >Remarks : <b>{{ goods.remarks }}</b></label
            >
          </div>
        </div>
      </v-row>
      <v-row no-gutters>
        <div
          class="col"
          style="text-align: center;
            padding-bottom: 0px;
            border-bottom: 1px dashed #000;
            background: #cfcfcf4a;
          "
        >
          Item Details
        </div>
      </v-row>
      <v-row no-gutters style="font-weight: bold; font-size: 15px">
        <v-col class="col-1 pp2 text-left">Sl </v-col>
        <v-col class="col-4 pp2 text-left">Item Name </v-col>
        <v-col class="col-3 pp2 text-left">Barcode </v-col>
        <v-col class="col-3 pp2 text-right">Quantity </v-col>
        <v-col class="col-1 pp2 text-right"> </v-col>
      </v-row>
      <div
        class="scrl"
        style="padding-top: 5px; text-align: left; font-size: 13px"
      >
        <v-row no-gutters v-for="(item, i) in itemDetails" :key="i">
          <v-col class="col-1 pp2 text-left">{{ i + 1 }} </v-col>
          <v-col class="col-4 pp2 text-left">{{ item.Remarks }} </v-col>
          <v-col class="col-3 pp2 text-left">{{ item.Barcode }} </v-col>
          <v-col class="col-3 pp2 text-right">{{ item.Quantity }} </v-col>
          <v-col class="col-1 pp2 text-right">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="#000"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  style="font-size: 19px"
                >
                  mdi-dots-vertical
                </v-icon>
              </template>

              <v-list>
                <v-list-item @click="edit(i)">
                  <v-icon style="font-size: 22px; color: green">
                    mdi-pencil-circle
                  </v-icon>
                </v-list-item>
                <v-list-item @click="dlt(i)">
                  <v-icon style="font-size: 22px; color: red">
                    mdi-delete-circle
                  </v-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </div>
     
    </v-container>
    <v-container>
      <v-row>
        <v-bottom-navigation v-model="value" grow style="box-shadow: none">
          <v-btn value="recent" @click="clearall">
            <span>Clear All</span>

            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-btn value="favorites" @click="itemReg">
            <span>Item Register</span>

            <v-icon>mdi-view-list</v-icon>
          </v-btn>
          <v-btn value="favorites" @click="Upload" hidden>
            <span>Upload</span>

            <v-icon>mdi-cloud-upload</v-icon>
          </v-btn>

          <v-btn value="nearby" @click="updateItemRegister">
            <span>Update</span>

            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn value="ad" @click="sheet = !sheet;edit_ = false;reset_()">
            <span>Add</span>

            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-row>
      <v-bottom-sheet v-model="gsheet"  fixed
      bottom >
        <v-sheet class="text-center" height="350px" style="padding: 10px">
          <v-row style="border-bottom: 1px dotted #000">
            <v-col cols="12">Goods Receipt</v-col>
          </v-row>

          <v-row style="padding-left: 10px; padding-right: 10px">
            <v-col cols="6">
              <v-text-field
                v-model="goods.number"
                outlined
                label="Receipt Number"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="goods.title"
                label="Supplier"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row style="padding-left: 10px; padding-right: 10px">
            <v-col cols="6">
              <v-text-field
                v-model="goods.date"
                outlined
                label="Date"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="goods.remarks"
                label="Remarks"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" style="padding-top: 0px"
              ><v-btn color="red" dark @click="gsheet = !gsheet">
                <v-icon> mdi-keyboard-backspace</v-icon> </v-btn
              >&nbsp;
              <v-btn color="warning" dark @click="clear">
                <v-icon>mdi-close-box</v-icon> </v-btn
              >&nbsp;
              <v-btn color="blue" dark @click="addreceipt">
                <v-icon>mdi-checkbox-marked</v-icon>
              </v-btn></v-col
            >
          </v-row>
        </v-sheet>
      </v-bottom-sheet>

      <v-bottom-sheet v-model="sheet" persistent>
        <v-sheet class="text-center" height="350px" style="padding: 10px">
          <v-row style="border-bottom: 1px dotted #000">
            <v-col cols="12">Add Item</v-col>
            <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px">
              <v-checkbox
                style="margin-top: 0px; padding-top: 0px"
                v-model="checkbox"
                label="Always open cam scanner"
                id="checkbox1zaal1"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row style="padding-left: 10px; padding-right: 10px">
            <v-col cols="6">
              <v-text-field
                v-model="b_barcode"
                outlined
                label="Barcode"
                append-icon="mdi-barcode"
                @click:append="scanbarcode"
                @change="getItemname"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="b_quantity"
                label="Quantity"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            style="padding-left: 10px; padding-right: 10px; margin-top: -30px"
          >
            <v-col cols="12">
              <v-text-field
                label="Item Name"
                v-model="b_itemname"
                outlined
              ></v-text-field>
              <!-- <v-text-field
                label="Item Name"
                v-model="pos"
                outlined
              ></v-text-field> -->
            </v-col>
          </v-row>
          <v-row v-show="!edit_">
            <v-col cols="12">
              <v-btn color="red" dark @click="sheet = !sheet">
                <v-icon> mdi-keyboard-backspace</v-icon> </v-btn
              >&nbsp;
              <v-btn color="warning" dark @click="clearitems">
                <v-icon>mdi-close-box</v-icon> </v-btn
              >&nbsp;
              <v-btn color="blue" dark @click="additem">
                <v-icon>mdi-checkbox-marked</v-icon>
              </v-btn>
            </v-col></v-row
          >
          <v-row v-show="edit_">
            <v-col cols="12">
              <v-btn color="red" dark @click="sheet = !sheet">
                <v-icon> mdi-keyboard-backspace</v-icon> </v-btn
              >&nbsp;
              <v-btn color="warning" dark @click="clearitems">
                <v-icon>mdi-close-box</v-icon> </v-btn
              >&nbsp;
              <v-btn color="blue" dark @click="update">
                <v-icon>mdi-checkbox-marked</v-icon>
              </v-btn>
            </v-col></v-row
          >
        </v-sheet>
      </v-bottom-sheet>
    </v-container>
    <v-dialog v-model="BarcodeModal" persistent width="500">
      <v-card>
        <v-card-title
          style="color: rgb(0 99 145); font-size: 17px; text-align: center"
          >Scan barcode</v-card-title
        >

        <v-card-text>
          <StreamBarcodeReader
            @decode="(a) => onDecode(a)"
            @loaded="() => onLoaded()"
          ></StreamBarcodeReader>
        </v-card-text>
        <v-card-actions hidden>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="BarcodeModal = false"
            style="background-color: #fb8c00; color: #fff"
          >
            Cancel
          </v-btn>

          <v-btn
            small
            @click="BarcodeModal = false"
            style="background-color: #2e7d32; color: #fff"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  <v-dialog v-model="deleModal" persistent width="500">
      <v-card>
        <v-card-title
          style="color:rgb(0 99 145);font-size:17px;text-align:center"
          >Are you sure you want to delete?</v-card-title
        >

        
        <v-card-actions hidden>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="deleModal = false"
            style="background-color:#FB8C00;color:#FFF"
          >
            Cancel
          </v-btn>

          <v-btn
            small
            @click="Delete_pos"
            style="background-color:#2E7D32;color:#FFF"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <vue-confirm-dialog></vue-confirm-dialog>

     <v-snackbar v-model="snack.snackbar" :timeout="2000">
     {{snack.msg}}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snack.snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      snack:{
        snackbar:false,
        msg:''
      },
      goods: {
        number: "",
        date: "",
        title: "",
        remarks: "",
      },
      b_itemname: "",
      b_barcode: "",
      b_quantity: "",
      sheet: false,
      itemDetails: [],
      BarcodeModal: false,
      edit_: false,
      pos: "",
      checkbox: false,
      gsheet: false,
      snackbar: false,
      VoucherDetails: [],
      URL__: localStorage.getItem("BaseURL"),
      ios: false,
       deleModal:false,
       dlePOS:''
    };
  },
  mounted() {
    this.string = this.URL__;
    this.substring = "https";
    this.Check_url = this.string.includes(this.substring);

    window.getResultGr = this.getResultGr;

    if (this.Check_url == true) {
      // alert(this.Check_url)
      this.ios = this.Check_url;
    } else {
      this.ios = this.Check_url;
    }
    // alert(this.Check_url)
  },
  created() {
    if (localStorage.getItem("EditItemDetails") !== null) {
      this.itemDetails = JSON.parse(
        localStorage.getItem("EditItemDetails") || "[]"
      );
    }
    if (localStorage.getItem("egds") !== null) {
      this.goods = JSON.parse(localStorage.getItem("egds"));
    }
    if (localStorage.getItem("showcam") !== null) {
      var checked = JSON.parse(localStorage.getItem("showcam"));

      if (checked == true) {
        this.checkbox = true;
        // document.getElementById("checkbox1zaal1").checked = true;
      } else {
        this.checkbox = false;
        // document.getElementById("checkbox1zaal1").checked = false;
      }
      this.checkbox = eval(localStorage.getItem("showcam"));
    }
  },
  watch: {
    checkbox: function () {
      localStorage.setItem("showcam", this.checkbox);
    },
  },
  methods: {
    reset_()
    {
      this.b_barcode = ''
      this.b_itemname = ''
      this.b_quantity = ''
    },
    onDecode(a) {
      console.log("Result Barcode " + a);
      // this.text = a;
      if (a != null || a != "") {
        this.b_barcode = a;
        this.BarcodeModal = false;
      }
    },
    clear() {
      this.goods = {};
      localStorage.setItem("egds", JSON.stringify(this.goods));
    },
    clearitems() {
      this.b_barcode = "";
      this.b_itemname = "";
      this.b_quantity = "";
      //  localStorage.setItem("gds", JSON.stringify(this.itemDetails));
    },
    onLoaded() {
      console.log("load");
    },
    scanbarcode() {
      //alert("aaa");
      this.BarcodeModal = true;
    },
    getItemname()
    {

if(this.b_barcode)
{
 if (this.ios == true) {
      this.axios
        .get(
          this.URL__ + "/api/item/getitembybarcode?barcode=" + this.b_barcode,
          null
        )
        .then(
          response => {
            console.log("Result" + JSON.stringify(response.data));
             const a =  JSON.stringify(response.data);
            if (a != '"Barcode does not exist"') {
              this.b_itemname = response.data.ItemName;
              
            } else {
              this.showw = false;
              // alert("Item Not Found !")
              this.snack.msg = "Item Not Found !";
              this.snack.snackbar = true;

              //  setTimeout(() => {
              //    window.history.back()
              //   }, 2000);
             
            }
          },
          error => {
            this.showw = false;
            if (error.response.status == 404) {
              this.showw = false;
            }
            // this.error = "Something went wrong..!";
          }
        );
      }
      else{
        //------------------------------------------------------------------------

          const myobj = {
           barcode: this.b_barcode
          };
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "getResultGr";
        var url_ = this.URL__ + "/api/item/getitembybarcode?barcode=" + this.b_barcode;

        var JsonData = 
      {
        "Json":
        [  
           {"URL":url_,"Method":method, "Params":obj,"Function":funtn_}
        ]

      };
        
    
      var aaa = JSON.stringify(JsonData);
      this.SendOBJ = aaa ;
       window.interface.CommonMethod(this.SendOBJ)
      }
}else{
             this.snack.msg = "Enter a Valid Barcode !";
              this.snack.snackbar = true;
}
    },

    getResultGr(res)
    {
     let obj = JSON.parse(res);
     
     this.b_itemname = obj.ItemName;
    
    },
    additem() {
      if (this.b_barcode == "" || this.b_quantity == "") {
       
        this.snack.msg = "Enter All details!";
        this.snack.snackbar = true;
      } else {
        var obj = {
          // receiptNo: this.goods.receiptNo,
          Barcode: this.b_barcode,
          Remarks: this.b_itemname,
          Quantity: this.b_quantity,
        };

        this.itemDetails.push(obj);
        localStorage.setItem(
          "EditItemDetails",
          JSON.stringify(this.itemDetails)
        );
        if (this.checkbox == true) {
          this.BarcodeModal = true;
        }
        
        this.reset_();

      }

      // this.sheet = false;
    },
    updateItemRegister() {
      this.Register = [];
      let lng =  JSON.parse(localStorage.getItem("EditItemDetails"));
      console.log("okk" +lng.length)
      
      if (lng.length == 0) {
        // alert("Enter All details");

         this.snack.msg = "Enter Item details"
         this.snack.snackbar = true;
      } else {


      if (localStorage.getItem("ItemRegister") != null) {
        this.Register = JSON.parse(localStorage.getItem("ItemRegister"));
      }
      // this.itemDetails_2 = ;
      const myobj = {
        VoucherId: 0,
        VoucherType: "GR",
        VoucherNo: this.goods.number,
        VoucherDate: this.goods.date,
        Title:this.goods.title,
        Remarks: this.goods.remarks,
        VoucherDetails: JSON.parse(localStorage.getItem("EditItemDetails")),
      };
      this.aaa = myobj;
      var position = localStorage.getItem("Editpos");
      console.log(myobj);
      this.snackbar = true;
      // this.aaaa = myobj;
      //  this.itemDetails[position].ListVoucherDetails = myobj;
      // this.objIndex = this.Register.findIndex(obj => obj.id == position);
      this.Register[position] = myobj
      // console.log("ssss" + JSON.stringify((this.Register[position] = myobj)));
      localStorage.setItem("ItemRegister", JSON.stringify(this.Register));
      this.snack.msg = "Updated"
      this.snack.snackbar = true;
     
      setTimeout(() =>  this.$router.push("/itemReg"), 1500);
      
    }
    },
    edit(position) {
      this.edit_ = true;
      console.log(this.itemDetails[position]);
      this.pos = position;
      // this.b_receiptNo = this.itemDetails[position].receiptNo;
      this.b_barcode = this.itemDetails[position].Barcode;
      this.b_itemname = this.itemDetails[position].Remarks;
      this.b_quantity = this.itemDetails[position].Quantity;
      this.sheet = true;
      // console.log("edit"+JSON.parse(this.itemDetails[position]))
    },
    addreceipt() {
      localStorage.setItem("gds", JSON.stringify(this.goods));
      this.gsheet = false;
    },
    update() {
      if (this.b_barcode == "" || this.b_quantity == "") {
        // alert("Enter All details");
        this.snack.msg = "Enter All details"
        this.snack.snackbar = true;
        document.getElementById("ebar").focus();
       
      } else {
      var position = this.pos;
      //Find index of specific object using findIndex method.
      this.objIndex = this.itemDetails.findIndex((obj) => obj.id == position);
      // this.itemDetails[position].receiptNo = this.b_receiptNo;
      this.itemDetails[position].Barcode = this.b_barcode;
      this.itemDetails[position].Remarks = this.b_itemname;
      this.itemDetails[position].Quantity = this.b_quantity;
      //---
      localStorage.setItem("EditItemDetails", JSON.stringify(this.itemDetails));
      this.sheet = false;
      this.pos = "";
      this.snack.msg = "Updated Successfully !";
      this.snack.snackbar = true;
      }
    },
    dlt(position) {
      if (this.ios == true) {
        // var result = confirm("");

        this.$confirm(
        {
          message: 'Want to delete  ?',
          button: {
            no: 'No',
            yes: 'Yes'
          },
 /**
          * Callback Function
          * @param {Boolean} confirm
          */
          callback: confirm => {
            if (confirm) {
              
            console.log(this.itemDetails[position]);
          this.itemDetails.splice(position, 1);
          // localStorage.setItem("ItemDetails", JSON.stringify(this.itemDetails));

          localStorage.setItem("EditItemDetails", JSON.stringify(this.itemDetails));

          
        
        
         } 
          }
        }
        )
      
      } else {
         this.deleModal = true;
         this.dlePOS = position;
      }
    },
    clearall() {
      if (this.ios == true) {
        // var result = confirm("Do you want to clear all data?");
        this.$confirm(
        {
          message: 'Do you want to clear all data?',
          button: {
            no: 'No',
            yes: 'Yes'
          },
          /**
          * Callback Function
          * @param {Boolean} confirm
          */
          callback: confirm => {
            if (confirm) {
              
          this.goods.number = "";
          // this.goods.date = "";
          this.goods.title = "";
          this.goods.remarks = "";
          this.itemDetails = [];
        
         } 
          }
        })
      }
      else {
        this.deleModal = true;
        this.dlePOS = "abc";
        this.modalmsg = "Do you want to clear all data?";
        }
        // if (result) {
    },
     Delete_pos()
    {
      if (this.dlePOS == "abc") {

        this.goods.number = "";

      this.goods.title = "";
      this.goods.remarks = "";
      this.itemDetails = []
       this.deleModal = false;
      } else {
          // this.itemReg.splice(this.dlePOS, 1);
          // localStorage.setItem("ItemRegister", JSON.stringify(this.itemReg));
          // this.deleModal = false;
          this.deleModal = false;
        this.itemDetails.splice(this.dlePOS, 1);
        localStorage.setItem("EditItemDetails", JSON.stringify(this.itemDetails));
      }
    },
    Upload() {
      this.itemObject = [];
      for (this.i = 0; this.i < this.itemDetails.length; this.i++) {
        // console.log(this.itemDetails[this.i].barcode);
        const obbj = {
          VoucherId: 0,
          Barcode: this.itemDetails[this.i].Barcode,
          Quantity: this.itemDetails[this.i].Quantity,
          Remarks: this.itemDetails[this.i].Remarks,
        };
        this.itemObject.push(obbj);
      }
      this.VoucherDetails.push(this.itemObject);
      
      const myobj = {
        VoucherId: 0,
        VoucherType: "GR",
        VoucherNo: this.goods.number,
        VoucherDate: this.goods.date,
        Title: this.goods.title,
        Remarks: this.goods.remarks,
        VoucherDetails: this.VoucherDetails,
      };
      this.url = localStorage.getItem("BaseURL");
      this.axios.post(this.url + "/api/PDTvoucher", myobj).then(
        (response) => {
          console.log("Result" + JSON.stringify(response.data));
        },
        (error) => {
          console.log("error" + error);
        }
      );
    },
    itemReg() {
      this.$router.push("itemReg");
    },
  },
};
</script>
<style scopped>
.pp2 {
  padding-bottom: 0px !important;
}
.pp3 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  font-size: 14px;
}
.scrl {
 /* min-height: 350px;
  max-height: 350px;*/
  min-height: 63vh;
  max-height:  63vh;
  overflow: scroll;
}
</style>
