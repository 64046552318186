<template>
  <div>
    <Header />
    <GitemReg />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import GitemReg from "../components/GitemReg.vue";
export default {
  components: {
    GitemReg,
    Header
  },
  data() {
    return {};
  },
  // beforeRouteLeave(to, from, next) {
  // //  console.log("sample name");
   
  // //   const answer = window.confirm('Do you want to leave this page?');
  // //   if (answer) {
  // //     next();
  // //   } else {
  // //     next(false);
  // //   }

  // }
  
};
</script>
