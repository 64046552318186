<template>
  <div class="">
   
    <v-card-text style="font-size:0.8rem !important;">
         
      <v-col cols="12">
        <v-card
          color="#fff"
          theme="light"
          style="margin-top:5px;"
        >
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-text style="padding-top:2em;">
              <v-text-field label="Select Date"
              v-model="check_date"
              required
              dense 
              type="date">
            </v-text-field>
            <v-combobox
            v-show="notmorethan1"
            v-model="selected_branch"
            label="Select Branch"
            :items="branches"
            dense
           ></v-combobox>
           <v-text-field 
          disabled
           v-show="!notmorethan1"
           v-model="selected_branch"
           label="Select Branch"
           >

           </v-text-field>
           

             
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text color="primary" @click="checkWithDate">ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card-text style="height: 20px; position: relative">
          <v-btn
            absolute
            dark
            fab
            top
            right
            color="blue"
            @click="dialog = !dialog"
          >
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-subtitle style="color:#000;padding-bottom: 0px;padding-top: 0px;text-align:center;">
          <i class="fa fa-calendar"></i> &nbsp; {{check_date}}
         </v-card-subtitle>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div style="width: 100%;text-align:center;">
            <v-avatar
            class="ma-3"
            size="115"
            rounded="0"
          >
          
            <!-- <v-img :src="require('@/assets/images/bg.png')"></v-img> -->
            <v-img src="https://static.vecteezy.com/system/resources/thumbnails/001/830/448/small/counter-with-cash-register-seller-old-man-and-woman-design-free-vector.jpg"></v-img>
          </v-avatar>
          
           <v-card-subtitle style="color:#000;padding-bottom: 0px;padding-top: 0px;text-align:left;display:none;">
           
            <v-avatar
            color="green"
            class="ma-3 "
            size="30"
          > {{  checkOpenedstatus()}}
        </v-avatar>
          <v-avatar
          color="red"
          class="ma-3 "
          size="30"
        > {{  checkClosedstatus()}}
        </v-avatar>
           </v-card-subtitle>
         
           
         
        </div>
            <div style="width: 100%;text-align:right;display:none;">
            
              <v-card-subtitle style="color:#000;    margin-top: 5em;">
                <v-row no-gutters >
                  <v-col cols="4" style="font-size:0.6rem; color:#000;font-weight:800;">
                   
                     Cash<br>
                     Credit<br>
                     Digital pay<br>
                     Others
                     <!-- Discount<br>
                     Tax<br>
                     Round Off -->
                    
                  </v-col>
                  <v-col cols="8">
                    <b style="color:#000;">{{formatPrice(SaleData.cash )}}</b><br>
                    <b style="color:#000;">{{formatPrice(SaleData.credit )}}</b><br>
                    <b style="color:#000;">{{formatPrice(SaleData.creditCard )}}</b><br>
                    <b style="color:#000;">{{formatPrice(SaleData.others )}}</b>
                    <!-- <b style="color:#000;">(-){{formatPrice(counteritem.discount )}}</b><br>
                    <b style="color:#000;">{{formatPrice(counteritem.tax )}}</b><br>
                    <b style="color:#000;">(-){{formatPrice(counteritem.roundOff )}}</b> -->
                  </v-col>
                </v-row>
               </v-card-subtitle>
             
              <hr style="    margin-left: 15px;">

              <v-card-actions style="text-align:right;float:right; color:#000;padding-top:0px">
              
                Total : &nbsp;<b>{{formatPrice(SaleData.totSale - (SaleData.discount + SaleData.roundOff ) )}}</b> 
               
              </v-card-actions>
             
            </div>
          

           
          </div>
          <div class="d-flex flex-no-wrap justify-space-between">
           
            <div style="width: 100%;text-align:left;">
              
              <v-card-subtitle style="color:#000;">
                <v-col style="font-size:0.6rem; color:#000;padding-left:0px;padding-bottom: 0px;">Other Details</v-col>
                <hr>
                <v-row no-gutters>
                 
                  <v-col cols="5" style="font-size:0.6rem; color:#000;font-weight:800;">
                   
                     Total Tax<br>
                     Discount<br>
                     Round Off<br>
                     Sales Return
                     
                    
                  </v-col>
                  <v-col cols="7" style="text-align:right">
                    <b style="color:#000;">{{formatPrice(SaleData.tax )}}</b><br>
                    <b style="color:#000;">{{formatPrice(SaleData.discount )}}</b><br>
                    <b style="color:#000;">{{formatPrice(SaleData.roundOff )}}</b><br>
                    <b style="color:#000;">{{formatPrice(SaleData.saleReturn )}}</b><br>
                    
                  </v-col>
                </v-row>
               </v-card-subtitle>
            
             

              <v-card-actions style="text-align:right;float:right; color:#000;">
              
              <!-- //here total   -->
              </v-card-actions>
            </div>
            <div style="width: 100%;text-align:right;">
              
              <v-card-subtitle style="color:#000;">
                <v-col style="font-size:0.6rem; color:#000;padding-left:0px;padding-bottom: 0px;text-align:right;">Sale Details</v-col>
                <hr>
                <v-row no-gutters>
                  <v-col cols="4" style="font-size:0.6rem; color:#000;font-weight:800;">
                   
                    Cash<br>
                    Credit<br>
                    Digital pay<br>
                    Others
                    <!-- Discount<br>
                    Tax<br>
                    Round Off -->
                   
                 </v-col>
                 <v-col cols="8">
                   <b style="color:#000;">{{formatPrice(SaleData.cash )}}</b><br>
                   <b style="color:#000;">{{formatPrice(SaleData.credit )}}</b><br>
                   <b style="color:#000;">{{formatPrice(SaleData.creditCard )}}</b><br>
                   <b style="color:#000;">{{formatPrice(SaleData.others )}}</b>
                   <!-- <b style="color:#000;">(-){{formatPrice(counteritem.discount )}}</b><br>
                   <b style="color:#000;">{{formatPrice(counteritem.tax )}}</b><br>
                   <b style="color:#000;">(-){{formatPrice(counteritem.roundOff )}}</b> -->
                 </v-col>
                </v-row>
               </v-card-subtitle>
              
             
              <hr style="    margin-left: 15px;">
              <v-card-actions style="text-align:right;float:right; color:#000;padding-top:0px">
              
                Total : &nbsp;<b>{{formatPrice( (SaleData.openingAmt + SaleData.cash )-SaleData.paidAmt )}}</b> 
               
              </v-card-actions>
            </div>
          

           
          </div>
         
        </v-card>
        
      </v-col>
    </v-card-text>
    <v-card
    :loading="loading"
    style="margin-top: 0px !important;font-size:0.8rem !important;"
    class="mx-auto my-12"
    max-width="374"
    v-if="counterData.length != 0"
  >
    

   

   

    <v-divider class="mx-4 mb-1"></v-divider>

    
    
   </v-card>

         
     
  </div>
</template>
<script>
import axios from 'axios'

export default {

   data() {
    return {
       title:'Sales Details',
       URL__: localStorage.getItem("BaseURL"),
       TodayDate:  null,
       SaleData:[],
       counterData:[],
       integerLength:3,
       show: false,
       dialog:false,
       check_date:null,
       selected_branch:null,
       branches:[],
       notmorethan1:false,
       number : null,
       ios:false,
       
    }
   
    },
    mounted(){
      
    this.string = this.URL__;
    this.substring = "https";
    this.Check_url = this.string.includes(this.substring);
    if (this.Check_url == true) {
      // alert(this.Check_url)
      this.ios = this.Check_url
    } 
    else {
      this.ios = this.Check_url;
    } 
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd ;
      this.TodayDate = today
      this.check_date = today;
      window.NativeCountergSales_ = this.NativeCountergSales_;
      this.branches = JSON.parse(localStorage.getItem("Branches"))
       this.branches.push("All")
        // this.branches.push("All")
        
        // alert("Bra"+this.branches)
        if (localStorage.getItem('isbranchCode') === null || localStorage.getItem('isbranchCode') === '') {
          this.selected_branch = "All";
          if(this.branches.length > 0)
            {
              this.notmorethan1 = true;
              // alert("aa")
            }
            else {
          
              this.selected_branch = localStorage.getItem("isbranchCode");
              this.notmorethan1 = false;
              // alert("bb")
            }
            // 
   
        }else{
          if(localStorage.getItem('role') === "Administrator")
          {
            this.selected_branch = "All";
            this.notmorethan1 = true;
            // alert("a")
          }
          else{
            this.notmorethan1 = false;
            this.selected_branch = this.branches[0];
            // alert("bb")
          }

        }

      
     
      this.checkWithDate();
     
    },
    methods:{
      
      checkWithDate()
        {
          this.SaleData = [];
          this.counterData = [];
          this.dialog = false;
           if(this.selected_branch === null)
           {
            this.selected_branch = "All"
           }
           
          if (this.ios == true) {
          this.axios
        .get(
          this.URL__ + "/api/counter/GetAllSaleSummary?ForDate="+this.check_date+"&BranchCode="+this.selected_branch+"&WithSummary=True",
          null
        )
        .then(
          response => {
           
            this.SaleData =  response.data.result[0]; 
            let sdta = response.data.result;
            for (let i = 1; i < sdta.length; i++) {
                    this.counterData.push(sdta[i])
             }
             this.checkClosedstatus();
             this.checkOpenedstatus();
          },
          error => {
            this.showw = false;
            if (error.response.status == 404) {
              this.showw = false;
            }
            // this.error = "Something went wrong..!";
          }
        );
          }else{
            const myobj = {
    
  };
var obj = JSON.stringify(myobj);
var method = "Get";
var funtn_ = "NativeCountergSales_";
var url_ = this.URL__ + "/api/counter/GetAllSaleSummary?ForDate="+this.check_date+"&BranchCode="+this.selected_branch+"&WithSummary=True";


var JsonData = 
{
"Json":
[  
   {"URL":url_,"Method":method, "Params":obj,"Function":funtn_}
]

};


var aaa = JSON.stringify(JsonData);
this.SendOBJ = aaa ;
window.interface.CommonMethod(this.SendOBJ);

          }
        },

    NativeCountergSales_(res)
    {
      // alert("SSS"+res)
       let obj = JSON.parse(res);
       let length = JSON.stringify(obj.result.length);
       this.SaleData = obj.result[0];
      
            for (let i = 1; i < length; i++) {
                 this.counterData.push(obj.result[i])
                   
             }
              this.checkClosedstatus();
             this.checkOpenedstatus();
       
 
    },
     
     
      formatPrice(value)
    {
      
         if(value != undefined || !isNaN(value) )
          {

            return value.toFixed(localStorage.getItem("symbol"))
          }else{
            let dummyval = 0;
            return dummyval.toFixed(localStorage.getItem("symbol"))
          }
    },
      removeSecondPeriod(str) {
            const firstIndex = str.indexOf('.');
            if (firstIndex === -1) {
              return str;
            }
            const secondIndex = str.indexOf('.', firstIndex + 0);
            if (secondIndex === -1) {
              return str;
            }
            return str.substring(0, secondIndex) + str.substring(secondIndex + 1);
      },
      checkClosedstatus()
        {
          const arr = this.counterData;
          const nameToCount = 'C';

            let count = 0;
            for (let i = 0; i < arr.length; i++) {

              // console.table(arr[i]);
              if (arr[i].counterStatus === nameToCount) {
                count++;
              }
            }
         return count;
          
        },
        checkOpenedstatus()
        {
          const arr = this.counterData;
          const nameToCount = 'O';

            let count = 0;
            for (let i = 0; i < arr.length; i++) {

              // console.table(arr[i]);
              if (arr[i].counterStatus === nameToCount) {
                count++;
              }
            }
         return count;
          
        },
      

    }
}

</script>