<template>
    <div>
        <v-form>
            <v-container>
              <v-row>
                <v-col>
                <v-img :src="require('@/assets/images/pref.gif')" class="elevation-0" max-height="280"></v-img>
                </v-col>
              </v-row>
              
              <v-row>
            
                    <v-col cols="6" sm="6" md="6" v-show="visiblecost">

                        <v-switch
                          
                         v-model="showCost"
                         label="Show Cost"
                         color="info"
                         hide-details
                         @change="AssignVal('Show Cost')"
                       ></v-switch>
                      
                     </v-col>

              
                <v-col cols="6" sm="6" md="6">
                  <v-switch
                   v-model="showSupp"
                   label="Show Supplier"
                   color="info"
                   @change="AssignVal('Show Supplier')"
                   hide-details
                 ></v-switch>
                </v-col>
              </v-row>
              <v-col cols="12" class="text-center">
                <v-btn color="info" class="mr-6" @click="save_preference">
                  Save
                </v-btn>
              </v-col>
            </v-container>
         </v-form>

    </div>
</template>
<script>
export default {
  data() {
    return {
            showCost:null,
            visiblecost:false,
            showSupp:false
            
        }
    },
    mounted() {
      if (localStorage.getItem("showsupp") !== null) {
      this.b =  localStorage.getItem("showsupp");
      this.showSupp = JSON.parse(this.b);//.replaceAll("^\"|\"$", "");
      }
      if( localStorage.getItem("role") == "Administrator")
      {
        this.visiblecost = true
      }
     
     if( localStorage.getItem("HideCost") == "True" )
     {
      this.showCost = true
     
     }else{
     
      if(localStorage.getItem("HideCost_preference") == "true")
      {
        this.showCost = true
     
      }else{
        this.showCost = false
        
      }
     
     }
    
    },
    methods:{
      AssignVal(id)
      {
        switch (id) {
            case 'Show Cost':
            localStorage.setItem("HideCost_preference",JSON.stringify(this.showCost))
            break;
            case 'Show Supplier':
         
            // localStorage.setItem("showsupp",JSON.stringify(this.showCost))
            localStorage.setItem("showsupp", this.showSupp);
            break;
           
          default:
            console.log('Unknown Data.');
        }
      },
      save_preference()
      {
        localStorage.setItem("HideCost_preference", JSON.stringify(this.showCost));
        localStorage.setItem("showsupp",  this.showSupp);
        this.$router.push("/");
      }
    }
   
}
</script>