<template>
  <div>
    <Header />
    <Stock />
  </div>
</template>
<script>
import Vue from 'vue'
import VuetifyConfirm from 'vuetify-confirm'
import Vuetify from 'vuetify'
import Header from "../components/Header.vue";
import Stock from "../components/Stock.vue";
Vue.use(VuetifyConfirm, {
  Vuetify,
  buttonTrueText: 'Yes',
  buttonFalseText: 'Cancel',
  color: 'warning',
  icon: 'warning',
  title: 'Warning',
  width: 350,
  property: '$confirm'
})
export default {
  components: {
    Stock,
    Header
  },
  data() {
    return {};
  },
  beforeRouteLeave(to, from, next) {
  if(localStorage.getItem('physicalItemDetails') === null || localStorage.getItem('physicalItemDetails') == '[]') {
 location.reload();
}
else if(localStorage.getItem('cangoback') == 0)
{
  next();
}
else {

   this.$confirm('Do you really want to leave this page?', { title: 'Warning' })
    .then(result => {
    if (result) {
      console.log('User clicked OK.');
      next();
      // Do something here if the user clicked OK.
    } else {
      console.log('User clicked Cancel.');
      next(false);
      // Do something here if the user clicked Cancel.
    }
  })
  .catch(error => {
    console.log('An error occurred: ', error);
    // Handle the error here.
  });
   
  }
}
};
</script>
