<template>
  <div>
    <v-container>
      <v-row>
        <v-col style="padding: 0px 70px 0px 70px;">
          <v-img
            :src="require('@/assets/images/barcode-scan.gif')"
            max-height="280"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-on:keyup.enter="scan"
            v-model="scannedbarcode"
            label="Barcode"
            outlined
            ref="scanbar"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      scannedbarcode: "",
      outputValueOnEnter: ""
    };
  },
  mounted() {
    this.$refs.scanbar.focus();
  },
  methods: {
    scan(e) {
      localStorage.setItem("barcode", e.target.value);
       this.$router.push("/result");
    },
    
  }
};
</script>
