<template>
  <div>
    <v-snackbar top :timeout="2000" :color="colormsg" v-model="snackbar">
      {{ msg__ }}
    </v-snackbar>
    <v-container class="grey lighten-5 mb-6">
      <v-row no-gutters>
        <v-col
          style="text-align:center;padding-bottom:0px;    border-bottom: 1px dashed #000;background: #cfcfcf4a;"
        >
          Sales Order
        </v-col>
      </v-row>
      <v-row no-gutters>
        <div class="row" style="padding:5px;    font-size: 13px;">
          <div class="col" style="" @click="gsheet = !gsheet">
            <label for=""
              >Number # : <b>{{ order.number }}</b></label
            ><br />
            <label for=""
              >Customer : <b>{{ order.title }}</b></label
            >
          </div>
          <div class="col" style="text-align:right;" @click="gsheet = !gsheet">
            <label for=""
              >Date  : <b>{{ order.date }}</b></label
            ><br />
            <label for=""
              >Remarks : <b>{{ order.remarks }}</b></label
            >
          </div>
        </div>
      </v-row>
      <v-row no-gutters>
        <div
          class="col"
          style="text-align:center;padding-bottom:0px;    border-bottom: 1px dashed #000;background: #cfcfcf4a;"
        >
          Item Details
        </div>
      </v-row>
      <v-row no-gutters style="font-weight:normal;font-size: 15px;">
        <v-col class="col-1 pp2 text-left">Sl </v-col>
        <v-col class="col-4 pp2 text-left">Item Name </v-col>
        <v-col class="col-3 pp2 text-left">Barcode </v-col>
        <v-col class="col-3 pp2 text-right">Quantity </v-col>
        <v-col class="col-1 pp2 text-right"> </v-col>
      </v-row>
      <div
        class="scrl"
        style="padding-top:5px;text-align:left;font-size: 13px; font-weight:bold;"
      >
        <v-row no-gutters v-for="(item, i) in itemDetails" :key="i">
          <v-col class="col-1 pp2 text-left">{{ i + 1 }} </v-col>
          <v-col class="col-4 pp2 text-left">{{ item.Remarks }} </v-col>
          <v-col class="col-3 pp2 text-left">{{ item.Barcode }} </v-col>
          <v-col class="col-3 pp2 text-right">{{ item.Quantity }} </v-col>
          <v-col class="col-1 pp2 text-right">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="#000"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  style="font-size: 19px;"
                >
                  mdi-dots-vertical
                </v-icon>
              </template>

              <v-list>
                <v-list-item @click="edit(i)">
                  <v-icon style="font-size:22px;color: green;">
                    mdi-pencil-circle
                  </v-icon>
                </v-list-item>
                <v-list-item @click="dlt(i)">
                  <v-icon style="font-size:22px;color: red;">
                    mdi-delete-circle
                  </v-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <v-container>
        <v-row>
          <v-bottom-navigation
            v-model="value"
            grow
            fixed
            bottom
            style="    box-shadow: none;"
          >
            <v-btn value="recent" @click="clearall">
              <span>Clear All</span>

              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-btn value="favorites" @click="itemReg">
              <span>Registers</span>

              <v-icon>mdi-view-list</v-icon>
            </v-btn>
            <v-btn value="upload" @click="Upload" hidden>
              <span>Upload</span>

              <v-icon>mdi-cloud-upload</v-icon>
            </v-btn>

            <v-btn value="save" @click="saveItemRegister">
              <span>Save</span>

              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn value="add" @click="sheet = !sheet;edit_ = false">
              <span>Add</span>

              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
          </v-bottom-navigation>
        </v-row>
        <v-bottom-sheet v-model="gsheet">
          <v-sheet class="text-center" height="350px" style="padding:10px;">
            <v-row style="border-bottom:1px dotted #000;">
              <v-col cols="12">Sales Order </v-col>
            </v-row>

            <v-row style="padding-left:10px;padding-right:10px;">
              <v-col cols="6">
                <v-text-field
                  v-model="order.number"
                  outlined
                  label="Order Number"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="order.title"
                  label="Customer"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="padding-left:10px;padding-right:10px;">
              <v-col cols="6">
                <v-text-field
                  v-model="order.date"
                  outlined
                  label="Date"
                  type="date"
                ></v-text-field>
                
              
                
                  </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="order.remarks"
                  label="Remarks"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="padding-top: 0px;"
                ><v-btn color="red" dark @click="gsheet = !gsheet">
                  <v-icon> mdi-keyboard-backspace</v-icon> </v-btn
                >&nbsp;
                <v-btn color="warning" dark @click="clear">
                  <v-icon>mdi-close-box</v-icon> </v-btn
                >&nbsp;
                <v-btn color="blue" dark @click="addreceipt">
                  <v-icon>mdi-checkbox-marked</v-icon>
                </v-btn></v-col
              >
            </v-row>
          </v-sheet>
        </v-bottom-sheet>

        <v-bottom-sheet v-model="sheet" persistent>
          <v-sheet class="text-center" height="350px" style="padding:10px;">
            <v-row style="border-bottom:1px dotted #000;">
              <v-col cols="12">Add Item</v-col>
              <v-col cols="7" style="padding-top:0px;padding-bottom:0px;">
                <v-checkbox
                  style="margin-top: 0px; padding-top: 0px;"
                  v-model="checkbox"
                  label="Always open cam scanner"
                  id="checkbox1zaal1"
                ></v-checkbox>
              </v-col>
              <v-col cols="5" style="padding-top:0px;padding-bottom:0px;text-align:right;font-size:10px;">
                Wholesale Rate: <b style="color:rgb(55, 167, 201);"> {{formatCurrency(wrate_)}}</b> <br>
                <!-- {{formatCurrency(wrate_)}} -->
                Retail Rate:  <b style="color:rgb(55, 167, 201);">{{formatCurrency(rrate_)}}</b>
              </v-col>
              <!-- @input="moveToNext('quan')" -->
            </v-row>
            <v-row style="padding-left:10px;padding-right:10px;">
              <v-col cols="6">
                <v-text-field
                  v-model="b_barcode"
                  outlined
                  id="ebar"
                  autofocus
                  label="Barcode"
                  append-icon="mdi-barcode"
                  @keyup.enter="moveToNext2()"
                  @change="getItemname"
                  @click:append="scanbarcode"
                ></v-text-field>
              
              </v-col>
              <v-col cols="6">
                <v-text-field
                  ref="b_quantity"
                  id="quan"
                  v-model="b_quantity"
                  label="Quantity"
                  type="number"
                  @keyup.enter="additem"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              style="padding-left:10px;padding-right:10px;margin-top: -30px;"
            >
              <v-col cols="12">
                <v-text-field
                  @keyup.enter="additem"
                  id="iname"
                  label="Item Name"
                  v-model="b_itemname"
                  outlined
                ></v-text-field>
                <!-- <v-text-field
                  label="Item Name"
                  v-model="pos"
                  outlined
                ></v-text-field> -->
              </v-col>
            </v-row>
            <v-row  style="justify-content: center;" class="mt-0">
              <!-- <span class="text-center " v-if="shwitmerr">{{ error_itemnotfound }}</span> -->
            </v-row>
            <v-row v-show="!edit_">
              <v-col cols="12">
                <v-btn color="red" dark @click="sheet = !sheet">
                  <v-icon> mdi-keyboard-backspace</v-icon> </v-btn
                >&nbsp;
                <v-btn color="warning" dark @click="clearitems">
                  <v-icon>mdi-close-box</v-icon> </v-btn
                >&nbsp;
                <v-btn color="blue" dark @click="additem">
                  <v-icon>mdi-checkbox-marked</v-icon>
                </v-btn>
              </v-col></v-row
            >
            <v-row v-show="edit_">
              <v-col cols="12">
                <v-btn color="red" dark @click="sheet = !sheet">
                  <v-icon> mdi-keyboard-backspace</v-icon> </v-btn
                >&nbsp;
                <v-btn color="warning" dark @click="clearitems">
                  <v-icon>mdi-close-box</v-icon> </v-btn
                >&nbsp;
                <v-btn color="blue" dark @click="update">
                  <v-icon>mdi-checkbox-marked</v-icon>
                </v-btn>
              </v-col></v-row
            >
          </v-sheet>
        </v-bottom-sheet>
      </v-container>
    </v-container>

    <v-dialog v-model="BarcodeModal" persistent width="500">
      <v-card>
        <v-card-title
          style="color:rgb(0 99 145);font-size:17px;text-align:center"
          >Scan barcode</v-card-title
        >

        <v-card-text>
          <StreamBarcodeReader
            @decode="a => onDecode(a)"
            @loaded="() => onLoaded()"
          ></StreamBarcodeReader>
        </v-card-text>
        <v-card-actions hidden>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="BarcodeModal = false"
            style="background-color:#FB8C00;color:#FFF"
          >
            Cancel
          </v-btn>

          <v-btn
            small
            @click="BarcodeModal = false"
            style="background-color:#2E7D32;color:#FFF"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snack.snackbar" :timeout="2000">
     {{snack.msg}}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snack.snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

     <v-dialog v-model="deleModal" persistent width="500">
      <v-card>
        <v-card-title
          style="color:rgb(0 99 145);font-size:17px;text-align:center"
          >{{modalmsg}}</v-card-title
        >

        
        <v-card-actions hidden>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="deleModal = false"
            style="background-color:#FB8C00;color:#FFF"
          >
            Cancel
          </v-btn>

          <v-btn
            small
            @click="Delete_pos"
            style="background-color:#2E7D32;color:#FFF"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
   
  components: {
    StreamBarcodeReader
  },
  data() {
    return {
      snack:{
        snackbar:false,
        msg:''
      },
      shwitmerr:false,
      order: {
        number: '',
        date: this.Mydate__,
        title: '',
        remarks: '',
        
      },
      wrate_:"",
      rrate_:"",
      b_itemname: "",
      b_barcode: "",
      b_quantity: "",
      sheet: false,
      itemDetails: [],
      BarcodeModal: false,
      edit_: false,
      pos: "",
      checkbox: false,
      gsheet: false,
      error_itemnotfound:'itemnot found',
      VoucherDetails: [],
      snackbar: false,
      msg__: "",
      URL__: localStorage.getItem("BaseURL"),
      colormsg:'',
       ios: false,
       deleModal:false,
       dlePOS:'',
       modalmsg:'',
       Mydate__:'',
       decimalPlaces:localStorage.getItem("symbol"),
    };
  },
  
   mounted()
  {
    
     this.Mydate__ = new Date().toISOString().slice(0,10);
    //  alert(this.goods.date)
   
  
     this.string = this.URL__;
    this.substring = "https";
    this.Check_url = this.string.includes(this.substring);
    if (this.Check_url == true) {
      // alert(this.Check_url)
      this.ios = this.Check_url;
    } else {
      this.ios = this.Check_url;
    }

          window.getResultSalesorder = this.getResultSalesorder;

      this.order.number = "";
      // this.goods.date = "";
      this.order.title = "";
      this.order.remarks = "";
      this.itemDetails = []
     
   
  },
  
  created() {
    if (localStorage.getItem("salesorderItemDetails") !== null) {
      this.itemDetails = JSON.parse(
        localStorage.getItem("salesorderItemDetails") || "[]"
      );
    }
    if (localStorage.getItem("order") !== null) {
      this.order = JSON.parse(localStorage.getItem("order"));
    }
    if (localStorage.getItem("showcam") !== null) {
      var checked = JSON.parse(localStorage.getItem("showcam"));

      if (checked == true) {
        this.checkbox = true;
        // document.getElementById("checkbox1zaal1").checked = true;
      } else {
        this.checkbox = false;
        // document.getElementById("checkbox1zaal1").checked = false;
      }
      this.checkbox = eval(localStorage.getItem("showcam"));
      
     
    }
  
   
      
      
    
  },
 
  watch: {
    checkbox: function() {
      localStorage.setItem("showcam", this.checkbox);
    },
    
    Mydate__: function(val) {
        this.order.date = val;
       
      },
  
  },
  
  methods: {
    
    onDecode(a) {
      console.log("Result Barcode " + a);
      // this.text = a;
      if (a != null || a != "") {
        this.b_barcode = a;
        
        this.BarcodeModal = false;
        this.moveToNext('quan');
      }
    },
    moveToNext2() {
    
        const nextField = document.getElementById('quan'); // Get the next field
        nextField.focus(); // Move focus to the next field
      
    },
    moveToNext(nextFieldId) {
      const currentField = document.activeElement; // Get the current active field
      if (currentField.value.length > 0) {
        const nextField = document.getElementById(nextFieldId); // Get the next field
        nextField.focus(); // Move focus to the next field
      }
    },
    clear() {
      this.order = {};
      localStorage.setItem("salesorderls", JSON.stringify(this.order));
    },
    clearall() {
   
     if (this.ios == true) {
      var result = confirm("Do you want to clear all data?");
      if (result) {
      this.order.number = "";
      // this.goods.date = "";
      this.order.title = "";
      this.order.remarks = "";
      this.itemDetails = []
      }
      

       }
       else {
          this.deleModal = true;
          this.dlePOS = "abc";
          this.modalmsg= "Do you want to clear all data?";
      }

    },
    jumpTOnext()
    {
      const input = document.getElementById("quan");
      input.focus();
    },
    getItemname()
    {
      this.moveToNext2();

 if (this.ios == true) {
      this.axios
        .get(
          this.URL__ + "/api/item/getitembybarcode?barcode=" + this.b_barcode,
          null
        )
        .then(
          response => {
            console.log("Result" + JSON.stringify(response.data));
             const a =  JSON.stringify(response.data);
             if (a != '"Barcode deos not exist"') {
              this.b_itemname = response.data.ItemName;
              this.wrate_ = response.data.WholeSaleRateFinal;
              this.rrate_ = response.data.RetailRateFinal;
              
            } else {
              this.showw = false;
              this.shwitmerr =true;
              // alert("Item Not Found !")
              // window.history.back()
            }
          },
          error => {
            this.showw = false;
            if (error.response.status == 404) {
              this.showw = false;
            }
            // this.error = "Something went wrong..!";
          }
        );
      }else{
        //------------------------------------------------------------------------

          const myobj = {
           barcode: this.b_barcode
          };
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "getResultSalesorder";
        var url_ = this.URL__ + "/api/item/getitembybarcode?barcode=" + this.b_barcode;

        var JsonData = 
      {
        "Json":
        [  
           {"URL":url_,"Method":method, "Params":obj,"Function":funtn_}
        ]

      };
        
    
      var aaa = JSON.stringify(JsonData);
      this.SendOBJ = aaa ;
       window.interface.CommonMethod(this.SendOBJ)
      }
    },
    getResultSalesorder(res)
    {
       let obj = JSON.parse(res);
    //  alert("toekn"+obj);
      this.b_itemname = obj.ItemName;
      this.wrate_ = obj.WholeSaleRate;
      this.rrate_ = obj.RetailRate
     // alert("Res "+res)
    },
     Delete_pos()
    {
      if(this.dlePOS == "abc")
      {
      this.order.number = "";
      // this.goods.date = "";
      this.order.title = "";
      this.order.remarks = "";
      this.itemDetails = []
       this.deleModal = false;
      }else{
        
           this.deleModal = false;
        this.itemDetails.splice(this.dlePOS, 1);
        localStorage.setItem("salesorderItemDetails", JSON.stringify(this.itemDetails));
       
      }
        
    },
    clearitems() {
      this.b_barcode = "";
      this.b_itemname = "";
      this.b_quantity = "";
      //  localStorage.setItem("gds", JSON.stringify(this.itemDetails));
    },
    onLoaded() {
      console.log("load");
    },
    scanbarcode() {
      //alert("aaa");
      this.BarcodeModal = true;
    },
    additem() {
      if (this.b_barcode == "" ||   this.b_quantity == "") {
        // alert("Enter All details");
        this.snack.msg = "Enter All details"
        this.snack.snackbar = true;
        document.getElementById("ebar").focus();
       
      } else {
        var obj = {
          // receiptNo: this.goods.number,
          Barcode: this.b_barcode,
          Remarks: this.b_itemname,
          Quantity: this.b_quantity
        };
       
        this.itemDetails.push(obj);
        localStorage.setItem("salesorderItemDetails", JSON.stringify(this.itemDetails));
        if (this.checkbox == true) {
          this.BarcodeModal = true;
        }

        // this.goods.receiptNo = "";
        this.b_barcode = "";
        this.b_itemname = "";
        this.b_quantity = "";
        this.wrate_ = "";
        this.rrate_ = "";
        // this.$refs.ebar.$el.focus();
        document.getElementById("ebar").focus();
      }

      // this.sheet = false;
    },
    saveItemRegister() {
     if (this.order.number == null || this.order.number == "") {
       
         this.snack.msg = "Please Enter order Number"
         this.snack.snackbar = true;
    
      }else if(!this.itemDetails.length)
      {
         this.snack.msg = "Please Add Atleast one item"
         this.snack.snackbar = true;
      } 
      else
      {
      this.Register = [];
      if (localStorage.getItem("salesorderItemRegister") != null) {
        this.Register = JSON.parse(localStorage.getItem("salesorderItemRegister"));
      }
      this.itemDetails_2 = JSON.parse(localStorage.getItem("salesorderItemDetails"));
      const myobj = {
        // VoucherId: 0,
        VoucherType: "SO",
        VoucherNo: this.order.number,
        VoucherDate: this.order.date,
        Remarks: this.order.remarks,
        Title: this.order.title,
        UploadStatus: false,
        VoucherDetails: this.itemDetails_2
      };

      this.Register.push(myobj);
      localStorage.setItem("salesorderItemRegister", JSON.stringify(this.Register));
       this.order.number = "";
      // this.goods.date = "";
      this.order.title = "";
      this.order.remarks = "";
      this.itemDetails = []
      localStorage.setItem("cangoback",0)
         this.snack.msg = "Saved"
         this.snack.snackbar = true;
      }
    },
    edit(position) {
      this.edit_ = true;
      console.log(this.itemDetails[position]);
      this.pos = position;
      // this.b_receiptNo = this.itemDetails[position].receiptNo;
      this.b_barcode = this.itemDetails[position].Barcode;
      this.b_itemname = this.itemDetails[position].Remarks;
      this.b_quantity = this.itemDetails[position].Quantity;
      this.sheet = true;
      // console.log("edit"+JSON.parse(this.itemDetails[position]))
    },
    addreceipt() {
      if (this.order.number == null || this.order.number == "") {
       
         this.snack.msg = "Please Enter Order Number"
         this.snack.snackbar = true;
    
      } else if (this.order.date == null) {
      
         this.snack.msg = "Please select a date"
         this.snack.snackbar = true;
      } else {
        localStorage.setItem("salesorderls", JSON.stringify(this.order));
        this.gsheet = false;
      }
    },
    update() {
      var position = this.pos;
      //Find index of specific object using findIndex method.
      this.objIndex = this.itemDetails.findIndex(obj => obj.id == position);
      // this.itemDetails[position].receiptNo = this.b_receiptNo;
      this.itemDetails[position].Barcode = this.b_barcode; 
      this.itemDetails[position].Remarks = this.b_itemname;
      this.itemDetails[position].Quantity = this.b_quantity;
      //---
      if (this.b_quantity == null || this.b_quantity == "" || this.b_barcode == "" || this.b_barcode == null   ) 
      {
         this.snack.msg = "Please Enter order Number"
         this.snack.snackbar = true;
      }else
      {
        localStorage.setItem("salesorderItemDetails", JSON.stringify(this.itemDetails));
         this.snack.msg = "Updated Succesfully"
         this.snack.snackbar = true;
      this.sheet = false;
      this.pos = "";
      this.b_barcode = "";
      this.b_itemname = "";
      this.b_quantity = "";
      this.wrate_ = "";
      this.rrate_ = "";
      // this.$refs.ebar.$el.focus();
      document.getElementById("ebar").focus();
      }
    },
    dlt(position) {
      if (this.ios == true) {
      var result = confirm("Want to delete?");
      if (result) {
        console.log(this.itemDetails[position]);
        this.itemDetails.splice(position, 1);
        localStorage.setItem("salesorderItemDetails", JSON.stringify(this.itemDetails));
        this.snackbar = true;
        this.msg__ = "Deleted Successfully.";
        this.colormsg = "green";
      }
      }else{
         this.deleModal = true;
         this.dlePOS = position;
         this.modalmsg= "Are you sure you want to delete?";
      }
    },
    formatCurrency(amount) {
    // Round the number to the specified decimal places
    const roundedAmount = Number(amount).toFixed(this.decimalPlaces);
    
    // Format with commas as thousands separators
    return roundedAmount.toLocaleString(undefined, {
        minimumFractionDigits: this.decimalPlaces,
        maximumFractionDigits: this.decimalPlaces,
    });
    },
    Upload() {
      this.itemObject = [];
      for (this.i = 0; this.i < this.itemDetails.length; this.i++) {
        // console.log(this.itemDetails[this.i].barcode);
        const obbj = {
          // VoucherId: 0,
          Barcode: this.itemDetails[this.i].barcode,
          Quantity: this.itemDetails[this.i].quantity,
          Remarks: this.itemDetails[this.i].itemName
        };
        this.itemObject.push(obbj);
      }
      this.VoucherDetails.push(this.itemObject);
      const myobj = {
        //VoucherId: 0,
        VoucherType: "SO",
        VoucherNo: this.order.number,
        VoucherDate: this.order.date,
        Remarks: this.order.remarks,
        Title: this.order.title,
        UploadStatus: false,
        VoucherDetails: this.VoucherDetails
      };
      this.url = localStorage.getItem("BaseURL");
      this.axios.post(this.url + "/api/PDTvoucher", myobj).then(
        response => {
          console.log("Result" + JSON.stringify(response.data));
          this.colormsg = "green";
          this.msg__ = " Uploaded successfully";
          this.snackbar = true;
        },
        error => {
          console.log("error" + error);
          this.colormsg = "green";
          this.msg__ = " Uploaded successfully";
          this.snackbar = true;
        }
      );
    },
    itemReg() {
      this.$router.push("salesorderitemReg");
    }
  },
  
};
</script>
<style scopped>
.pp2 {
  padding-bottom: 0px !important;
}
.pp3 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  font-size: 14px;
}
.scrl {
  /*min-height: 57vh !important;
  max-height: 57vh !important;*/
  overflow: auto;
  min-height: 60vh;
}
</style>
