<template>
    <div class="">
         <Header />
         <Sales/>
    </div>
</template>
<script>
import Header from "../components/Header.vue";
import Sales from '../components/Salescomp.vue';
export default {
    components: {
        Sales,
        Header
  },
     data() {
      return {
        
      }
  }
}

</script>