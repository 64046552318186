<template>
  <div>
    <Header />
    <Barcoderesult />
  </div>
</template>
<script>
import Barcoderesult from "../components/Barcoderesult";
import Header from "../components/Header.vue";
export default {
  components: {
    Barcoderesult,
    Header
  },
  data() {
    return {};
  }
};
</script>
