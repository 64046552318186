<template>
  <div>
    <Header />
    <EditPS />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import EditPS from "../components/EditPS.vue";
export default {
  components: {
    EditPS,
    Header
  },
  data() {
    return {};
  }
};
</script>
