<template>
  <div>
    <Header />
    <Goods />
    
  </div>
</template>
<script>
import Vue from 'vue'
import VuetifyConfirm from 'vuetify-confirm'
import Vuetify from 'vuetify'
import Header from "../components/Header.vue";
import Goods from "../components/Goodsreceipt.vue";
Vue.use(VuetifyConfirm, {
  Vuetify,
  buttonTrueText: 'Yes',
  buttonFalseText: 'Cancel',
  color: 'warning',
  icon: 'warning',
  title: 'Warning',
  width: 350,
  property: '$confirm'
})
export default {
  components: {
    Goods,
    Header
  },
  data() {
    return {
      
    };
  },

  
  beforeRouteLeave(to, from, next) {
    let itemDetails;
    // if (localStorage.getItem("ItemDetails") !== null) {
    //   itemDetails = JSON.parse( localStorage.getItem("ItemDetails") || "[]" );
    //   console.log("Count"+itemDetails);
    // }

 if(localStorage.getItem('ItemDetails') === null || localStorage.getItem('ItemDetails') == '[]') {
 location.reload();
} 
else if(localStorage.getItem('cangoback') == 0)
{
  next();
}

else {
 

  //  console.log("sample name a"+localStorage.getItem("ItemDetails").length);
   this.$confirm('Do you really want to leave this page?', { title: 'Warning' })
    .then(result => {
    if (result) {
      console.log('User clicked OK.');
      next();
      // Do something here if the user clicked OK.
    } else {
      console.log('User clicked Cancel.');
      next(false);
      // Do something here if the user clicked Cancel.
    }
  })
  .catch(error => {
    console.log('An error occurred: ', error);
    // Handle the error here.
  });
   
  }
}
};
</script>
<style scoped>
.v-toolbar__content{
 justify-content: center !important;
}

.v-toolbar--dense .v-toolbar__content, .v-toolbar--dense .v-toolbar__extension {
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}
</style>